import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { AppImage, AppText } from "../../../components";
import UploadFileIcon from '../../../assets/icons/Upload.svg';
import NoteIcon from '../../../assets/icons/Note.svg';
import RemoveIcon from '../../../assets/icons/Remove.svg';
import api, { baseURL } from "../../../api";
import { insuranceAction } from "../../../_redux/actions/insurance";
import { InsuranceTypes } from "../../../_redux/types/insurance";
import { useDispatch } from "react-redux";
import "./index.css";

type UploadFileFieldProps = {
    className?: string;
    name?: string;
    setValue?: any;
    setIsTyping?: any;
    isTyping?: boolean;
}

const UploadFileField = ({ className, name, setValue, setIsTyping, isTyping }: UploadFileFieldProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const fileTypes = ["JPEG", "PNG", "PDF"];
    const [selectedFile, setSelectedFile] = useState({ name: "" });
    const [showNote, setShowNote] = useState(false);
    const [uploadError, setUploadError] = useState({ size: false, type: false });
    const handleChange = async (file: File) => {
        // 
        isTyping === false && setIsTyping(true);
        // clear errors
        setUploadError({ size: false, type: false })
        // clear last selection
        setShowNote(false)
        setValue(name, null);
        insuranceAction("")(InsuranceTypes.REMOVE_UPLOADED_FILE)(dispatch)
        // set the file to upload
        setSelectedFile(file);
        const formData = new FormData();
        formData.append('file', file);
        // upload the file
        return await api
            .post(`${baseURL}/api/v0/add-attachment-file`, formData, {
                params: { 'attach-type': "insurancePolice" },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                const status = res.status;
                const data = res.data;
                if (status === 201) {
                    setShowNote(true);
                    setValue(name, data);
                    insuranceAction(data)(InsuranceTypes.UPLOAD_FILE_SUCCESS)(dispatch)
                }
            })
            .catch(() => {
                throw new Error('Error in [uploadFiles]');
            });

    };

    return (
        <div className={className}>
            <AppText text='document' className="capitalize text-sm text-slight-black mb-4 -ml-2" />
            <FileUploader
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                maxSize={10}
                hoverTitle=" "
                onSelect={() => {
                    setUploadError({ size: false, type: false });
                }}
                onTypeError={() => {
                    setShowNote(false)
                    setValue(name, null);
                    insuranceAction("")(InsuranceTypes.REMOVE_UPLOADED_FILE)(dispatch)
                    setUploadError({ ...uploadError, type: true, size: false });
                }}
                onSizeError={() => {
                    setShowNote(false)
                    setValue(name, null);
                    insuranceAction("")(InsuranceTypes.REMOVE_UPLOADED_FILE)(dispatch)
                    setUploadError({ ...uploadError, size: true, type: false });
                }}
                children={
                    <div className="py-12 flex justify-center items-center border border-gray-border rounded-4">
                        <p className="mr-3">
                            {t("Drag_and_drop")}
                            <span className="underline" >
                                {t("click_to_browse")}
                            </span>
                        </p>
                        <AppImage src={UploadFileIcon} alt="upload fileIcon" />

                    </div>
                }
            />
            {uploadError.size && <p className="mt-4 text-red-error text-sm mobile:mb-4">{t('size_error')}</p>}
            {uploadError.type && <p className="mt-4 text-red-error text-sm mobile:mb-4">{t('type_error')}</p>}
            {
                selectedFile.name.length > 0 && showNote === true &&
                (
                    <div className="h-12 mt-4 bg-note-gray px-4 flex justify-between items-center  rounded-4 ">
                        <div className="flex items-center gap-2">
                            <AppImage src={NoteIcon} alt='noteIcon' />
                            <AppText text={selectedFile && `${selectedFile?.name}`} />
                            <AppText text="File_Uploaded" className="opacity-30" />
                        </div>
                        <div className="cursor-pointer" onClick={() => {
                            setShowNote(false)
                            setValue(name, null);
                            insuranceAction("")(InsuranceTypes.REMOVE_UPLOADED_FILE)(dispatch)
                        }}>
                            <AppImage src={RemoveIcon} alt="removeIcon" />
                        </div>
                    </div>)}
        </div >
    );
}

export default UploadFileField