import AppListItem from '../AppListItem/index'
interface AppListProps {
    className?: string;
    list_options: Array<any>;
    list_item_style?: string;
    withLinks?: boolean;
}

export default function AppList({ className, list_options, list_item_style, withLinks = false }: AppListProps): JSX.Element {
    return (
        <ul className={className}>
            {list_options.map((item, index) =>
                <AppListItem
                    key={index}
                    className={list_item_style}
                    withLinks={withLinks}
                    itemToDisplay={item} />
            )}
        </ul>

    )
}
