import { AppTypes } from './../types/app';
import { IState } from '../../_typescript/IAppReducer';
import { ReduxAction } from '../../_typescript/IAuthReducer';

const initialState: IState = {
    loading: false
}
const appReducer = (state = initialState, { type }: ReduxAction) => {
    switch (type) {
        case AppTypes.START_LOADING:
            return { ...state, loading: true };
        case AppTypes.STOP_LOADING:
            return { ...state, loading: false };
        default:
            return state;
    }
}
export default appReducer