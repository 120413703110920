import CardHeader from "./cardHeader/index";

type CardProps = {
    className?: string;
    title: string;
    logo: JSX.Element;
    children?: JSX.Element;
    onClick?: () => void;
    showDiscoverMore?: boolean;
};
const Card = ({ className, onClick, title, logo, children, showDiscoverMore = true }: CardProps): JSX.Element => {
    return (
        <div className={className}>
            <CardHeader
                onClick={onClick}
                className="lg:min-h-58  flex text-blue-link max-w-lg w-full items-center justify-between pr-4 pl-4 pt-2 pb-2 bg-light-blue mobile:p-2"
                title={title}
                logo={logo}
                showDiscoverMore={showDiscoverMore}
            />
            {children}
        </div>
    )
}
export default Card
