import IRoute from "../_typescript/IRoute"
import Home from "../pages/home"
import Profil from "../pages/profil"
import Insurance from "../pages/insurance"
import PersonalInformations from "../pages/profil/personal_information"
import Mandate from "../pages/mandate"
import Group from "../pages/group"
import Safe from "../pages/safe"
import { Redirect } from "react-router-dom"

export const routes: IRoute[] = [
    {
        path: '/',
        name: 'Home Page',
        component: Home,
        exact: true
    },
    {
        path: '/insurances',
        name: 'Insurance Page',
        component: Insurance,
        exact: false
    },
    {
        path: '/mandate',
        name: 'Mandat Page',
        component: Mandate,
        exact: true
    },
    {
        path: '/profil',
        name: 'Profile Page',
        component: Profil,
        exact: false
    },
    {
        path: '/group',
        name: 'Group Page',
        component: Group,
        exact: true
    },
    {
        path: '/safe',
        name: 'Safe Page',
        component: Safe,
        exact: true
    }
]

export const uncompleted_infos_routes: IRoute[] = [
    {
        path: '/profil/personal-infos',
        name: 'Profile Page',
        component: PersonalInformations,
        exact: true
    }
]