import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import { FieldError } from "react-hook-form";
import dayjs from "dayjs";
import { AppImage, AppText } from "../../../components";
import CalendarIcon from "../../../assets/icons/Calendar.svg"
import './index.css'
import "react-datepicker/dist/react-datepicker.css";

type DateInputFieldProps = {
    className?: string
    selectedValue?: string | any;
    setValue?: any;
    error?: FieldError;
    label?: string;
    labelStyle?: string;
    setIsTyping?: any;
    isTyping?: boolean;
    name?: string;
}

registerLocale('fr', fr);
registerLocale('en', en);
const app_locale = localStorage.getItem('seenk:CURRENT_LANGUAGE') || 'fr';
const date_locale = app_locale === "fr" ? "fr" : "en";
const DateInputField = ({ className, name, selectedValue, setIsTyping, labelStyle, isTyping, setValue, error, label }: DateInputFieldProps) => {
    const [startDate, setStartDate] = useState<any>(new Date());

    useEffect(() => {
        const initialValue = Date.parse(selectedValue) || new Date();
        selectedValue && setStartDate(initialValue);
    }, [selectedValue]);
    return (
        <>
            {label && <AppText className={`mb-4 ${labelStyle}`} text={label} />}
            <div className={` relative w-96 mobile:w-full ${className} `}>
                <DatePicker
                    withPortal
                    portalId="root-portal"
                    dateFormat="dd/MM/yyyy"
                    dateFormatCalendar="dd MMMM yyyy"
                    locale={date_locale}
                    className={`p-4 max-h-12 text-base ${error ? "mb-1 input-red-border" : "mb-4 input-gray-border"} w-full outline-none  font-normal  not-italic leading-full`}
                    selected={startDate}
                    onChange={(date: Date) => {
                        date && setStartDate(date)
                        setValue(name, dayjs(date).format('YYYY-MM-DD'));
                        isTyping === false && setIsTyping(true)
                    }}
                />
                <AppImage alt="calender" className="calender_icon" src={CalendarIcon} />
            </div>
        </>
    )
}

export default DateInputField;