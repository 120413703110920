import { AppLanguageSwitcher, AppList, AppText } from "../../../components/index"
import { footer_links, languages } from "../../../constants/sharedData";

import "./index.css"

export default function AuthFooter(): JSX.Element {
    return (
        <div>
            <div className="auth-footer pt-7 pb-7 grid grid-cols-footer border-t border-gray-border mobile:grid-cols-1 mobile:gap-8 mobile:p-6">
                <AppLanguageSwitcher
                    className="w-full max-w-224 font-NeueHaasUnica not-italic font-normal pl-4"
                    languages={languages}
                />
                <AppList
                    withLinks={false}
                    className="grid grid-cols-3 grid-rows-3 w-full justify-self-center font-NeueHaasUnica not-italic font-normal"
                    list_item_style="text-base leading-full text-slight-black pb-2"
                    list_options={footer_links}
                />
            </div>
            <AppText
                className="text-base pl-4 font-NeueHaasUnica not-italic font-normal leading-full text-slight-black pb-5"
                text="text_terms_privacy"
            />
        </div >
    )
}
