import INavIcon from "../../../_typescript/INavIcon"

export default function Insurance({ color, className }: INavIcon): JSX.Element {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke={color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 6h4M6 8h4M6 10h2M9.793 13.5H3a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v6.793a.499.499 0 01-.146.353l-3.208 3.208a.5.5 0 01-.353.146v0z"
        ></path>
        <path
          stroke={color || '#000'}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.455 10H10v3.454"
        ></path>
      </svg>
    </div>
  );
}
