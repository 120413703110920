
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "./index.css";
type DocsViewerProps = {
    className?: string;
    URL: string;
}
const DocsViewer = ({ className, URL }: DocsViewerProps) => {
    const { t } = useTranslation()
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess() {
        setNumPages(numPages)
    }


    return (
        <div className={className}>
            <Document
                file={{ url: URL }}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={t("loading")} >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            className="w-full h-full"
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                        />
                    )
                )}
            </Document>
        </div >
    );
}

export default DocsViewer;
