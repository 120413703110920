import ProfilNavbar from "./profil_navbar";
import ProfilRoutes from "./profil_routes";
import './index.css'
const Profil = (): JSX.Element => {
    return (
        <div className='profil_page bg-white w-5/6 xs:w-9/12 mobile:max-w-85vw mobile:w-11/12 mobile:overflow-hidden'>
            <ProfilNavbar />
            <div className="relative min-h-78  flex  flex-col justify-between  ">
                <ProfilRoutes />
            </div>
        </div>
    )
}
export default Profil;
