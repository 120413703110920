import { Fragment } from 'react'
import { Link } from 'react-router-dom';

interface AppInternLinkProps {
    text: string | null | undefined;
    to?: string | any;
    className?: string | undefined;
    onClick?: () => void;
}

export default function AppInternLink({ text, onClick, to, className }: AppInternLinkProps): JSX.Element {
    return (
        <Fragment>
            <Link onClick={onClick} to={to} className={className}>
                {text}
            </Link>
        </Fragment>
    )
}
