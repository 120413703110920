import { InsuranceTypes } from './../types/insurance';
import { IInsuranceModel } from './../../_typescript/IInsurancesModel';
import { ReduxAction } from "../../_typescript/IAuthReducer";

const initialState: IInsuranceModel = {
    policeFile: {},
}

const insuranceReducer = (state = initialState, { type, payload }: ReduxAction) => {
    switch (type) {
        case InsuranceTypes.UPLOAD_FILE_SUCCESS:
            return { ...state, policeFile: { fileName: payload.fileName }, };
        case InsuranceTypes.UPLOAD_FILE_FAILURE:
            return { ...state, errors: { upload_file: payload } };
        case InsuranceTypes.REMOVE_UPLOADED_FILE:
            return { ...state, policeFile: { fileName: payload }, };
        default:
            return state;
    }
}
export default insuranceReducer;