import { INSURANCE_PIN_COLORS } from '../../../constants/style';
type ValidationPinProps = {
    status: string;
    className?: string;
};
const handlePinColor = (status: string) => {
    switch (status) {
        case "valide":
            return INSURANCE_PIN_COLORS.valide;
        case "pending":
            return INSURANCE_PIN_COLORS.pending;
    }
}

const ValidationPin = ({ status, className }: ValidationPinProps): JSX.Element => {
    return (
        <span className={`w-1.5 h-1.5 rounded-full ${className}`} style={{
            background: handlePinColor(status),
        }}></span>
    );
}
export default ValidationPin;