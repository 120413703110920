import { useHistory } from 'react-router-dom';
import SuccessIcon from '../../../../assets/icons/Success.svg'
import AddIcon from '../../../../assets/icons/WhitePlus.svg'

import { AppButton, AppImage, AppText } from '../../../../components';

type MandateApprovedProps = {
    className?: string;
    ApprovalMessage: { title: string, detail: string };
}


const MandateApproved = ({ className, ApprovalMessage }: MandateApprovedProps): JSX.Element => {
    const history = useHistory()
    const { title, detail } = ApprovalMessage
    return (
        <div className={className + " flex flex-col gap-4 justify-center items-center h-full min-h-78"} >
            <AppImage src={SuccessIcon} className="w-28 h-28 mb-4" />
            <AppText text={title} className='text-2xl leading-6 text-slight-black' />
            <AppText text={detail} className='text-base leading-4 text-slight-black text-center' />
            <div className=' flex flex-col gap-4'>
                <AppButton
                    withIcon
                    iconSrc={AddIcon}
                    textStyle="text-sm  leading-4"
                    className="lg:min-w-304  h-10 bg-blue-button pr-0 text-white rounded-4  pl-4 "
                    text="add_insurance"
                    onClick={() => history.push('/insurances/add/new_insurance')}
                />

                <AppButton
                    textStyle="text-sm leading-4"
                    className="lg:min-w-304 h-10 bg-white pr-0 text-blue-button border border-blue-button rounded-4   flex items-center justify-center pl-4"
                    text="Back_home"
                    onClick={() => history.push('/')}
                />
            </div>
        </div>
    )
}

export default MandateApproved