import INavIcon from "../../../_typescript/INavIcon"

export default function Dashboard({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <rect
                    width="5.5"
                    height="7"
                    x="1.5"
                    y="1.5"
                    stroke={color || '#000'}
                    rx="0.5"
                ></rect>
                <rect
                    width="5.5"
                    height="4"
                    x="1.5"
                    y="10.5"
                    stroke={color || '#000'}
                    rx="0.5"
                ></rect>
                <rect
                    width="5.5"
                    height="4.5"
                    x="9"
                    y="1.5"
                    stroke={color || '#000'}
                    rx="0.5"
                ></rect>
                <rect
                    width="5.5"
                    height="6.5"
                    x="9"
                    y="8"
                    stroke={color || '#000'}
                    rx="0.5"
                ></rect>
            </svg>
        </div>
    );
}
