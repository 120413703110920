import { Fragment, useEffect, useState } from "react";
import Select from 'react-select'
import "./index.css"


interface FormSelectProps {
    className?: string;
    disabled?: boolean;
    select_options: any;
    indicator: boolean;
    setPhoneIndicator?: any;
    indicatorIndex?: number;
    isSearchable?: boolean;
}
export default function FormSelect({ className, indicatorIndex, isSearchable, disabled, setPhoneIndicator, select_options, indicator }: FormSelectProps): JSX.Element {
    const [currentIndicator, setCurrentIndicator] = useState(indicatorIndex!)
    useEffect(() => {
        setCurrentIndicator(indicatorIndex!)
    }, [indicatorIndex])

    useEffect(() => {
        setPhoneIndicator(select_options[currentIndicator].value)
    }, [currentIndicator])
    return (
        <Fragment>
            <Select
                className={className}
                components={!indicator ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : { IndicatorSeparator: () => null }}
                isDisabled={disabled}
                value={select_options[currentIndicator]}
                options={select_options}
                onChange={() => {
                    setCurrentIndicator(currentIndicator === 0 ? 1 : 0)
                }}
                isSearchable={isSearchable}

            />
        </Fragment >
    )
}
