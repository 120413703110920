import { useState, useEffect } from 'react'
import { AppText } from '../../../components';
import './index.css'

interface ResendCodeTimerProps {
    text: string | null | undefined;
}

const ResendCodeTimer = ({ text }: ResendCodeTimerProps): JSX.Element => {
    const [time, setTime] = useState(1);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isActive && time < 20) {
            const intervalID = setInterval(() => {
                setTime((prev) => prev + 1);
            }, 1000);
            return () => {
                clearInterval(intervalID);
            };
        } else {
            const intervalID = setInterval(() => {
                setIsActive(false);
                setTime(1);
            }, 500);
            return () => {
                clearInterval(intervalID);
            };

        }
    }, [isActive, time]);

    return (
        <>
            {!isActive ? (
                <AppText
                    onClick={() => setIsActive(!isActive)}
                    text={text}
                    className="text-blue-link  cursor-pointer font-NeueHaasUnica not-italic font-normal underline text-sm leading-4"
                />
            ) : (<div className='timer'>
                <div className="spinner">
                    <div className="cover">
                    </div>
                </div>
                <AppText
                    className='timer_display flex justify-center items-center text-sm text-slight-black mr-2 leading-4 font-NeueHaasUnica not-italic font-normal'
                    text={String(time)}
                />
            </div>
            )}
        </>
    )
}
export default ResendCodeTimer
