import { AppButton, AppImage, AppText } from "../../../../components"
import WarningIcon from "../../../../assets/icons/Warning.svg"
import BlueNoteIcon from "../../../../assets/icons/BlueNote.svg"
import { getMandate } from "../../../../api/insuranceApi"
import { useState } from "react"
import api, { baseURL } from "../../../../api"
import SignatureMandateModal from "../SignatureModal"
import "./index.css"
import Swal from "sweetalert2"

type ContractProps = {
    className?: string;
    docApproved: boolean;
    setDocApproval: (docApproved: boolean) => void;
}

const Contract = ({ className, docApproved, setDocApproval }: ContractProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [pdfLink, setPdfLink] = useState("");

    const handleMandate = () => {
        setIsLoading(true)
        getMandate().then((res) => {
            const { status, data } = res
            if (status === 201) {
                api
                    .get(`${baseURL + data.signedArt45Management}`, {
                        responseType: "arraybuffer"
                    })
                    .then((response) => {
                        const file = new Blob([response.data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        setPdfLink(fileURL);
                        setIsOpen(true)
                        setIsLoading(false)
                    });
            } else {
                Swal.fire({
                    icon: 'warning',
                    text: data.detail,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                setIsLoading(false)
            }
        })
    }

    return (
        <>
            <div className={className + ' bg-white mb-14'}>
                <div className="home_page-header  flex justify-between mobile:px-0 mobile:p-2 pb-4 border-b border-gray-border">
                    <AppText text="Review and Sign document" className='mr-6 pb-2 text-2xl mobile:text-xl leading-6 text-slight-black' />
                </div>
                <div className="flex justify-between w-full border mt-8 p-6 rounded-4 mobile:pt-4 tablet-min:flex-col tablet-min:gap-4 mobile:flex-col mobile:gap-4 " >
                    <div className="flex items-center gap-4">
                        <AppImage src={WarningIcon} className='w-5 h-4' />
                        <AppText text="Review_the_document" className='text-base leading-4 text-slight-black' />
                    </div>
                    <div className="relative flex justify-center items-center border  border-blue-link rounded-4">
                        {isLoading &&
                            <div>
                                <svg id="spinner"
                                    width="24px" height="24px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"  >
                                    <g className="progress-container">
                                        <circle className="progress" fill="none" stroke-miterlimit="10" cx="32" cy="32" r="30" />
                                    </g>
                                    <circle className="track" fill="none" stroke-miterlimit="10" cx="32" cy="32" r="30" />
                                </svg>
                            </div>
                        }
                        <AppButton
                            onClick={() => !isLoading && handleMandate()}
                            text="Read_the_document"
                            withIcon={true}
                            iconSrc={BlueNoteIcon}
                            className='ml-4 text-blue-link pl-12 pr-12 py-4 mobile:p-4 mobile:pl-0' />
                    </div>
                </div>
            </div>
            <SignatureMandateModal
                setDocApproval={setDocApproval}
                docApproved={docApproved}
                pdfLink={pdfLink}
                isOpen={isOpen}
                setIsOpen={setIsOpen} />
        </>
    )
}

export default Contract