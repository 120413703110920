import { ProfilIcon } from '../../../assets/icons/navIcons';
import { AppImage, AppText } from '../../../components';
import { useSelector } from 'react-redux';
import Card from '../../card';
import { baseURL } from '../../../api';
import { useHistory } from 'react-router-dom';
import IUserReducerState from '../../../_typescript/IUserReducerState';
type ProfileCardProps = {
    className?: string;
};

const ProfileCard = ({ className }: ProfileCardProps): JSX.Element => {
    const history = useHistory();
    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer?.user);
    const { lastName, firstName, image, email, phoneNumber } = UserInfos;
    return (
        <Card
            onClick={() => history.push('/profil/personal-infos')}
            title="Profil"
            logo={<ProfilIcon color="#1356DC" />}
            className={className + " rounded border border-gray-border max-w-card-width w-full max-h-card-height "} >
            <div className="w-full h-full grid grid-cols-infos p-6 mobile:p-2">
                <div className="">
                    {image && <AppImage alt="profil-avatar" className="h-20 w-20 object-contain" src={baseURL + image} />}
                </div>
                <div className="profil-body grid grid-cols-1 grid-rows-profil-body mobile:ml-2">
                    <div className="profil-body-header">
                        {lastName && firstName &&
                            <AppText text={lastName + " " + firstName} className="text-base mobile:text-xs  font-NeueHaasUnica not-italic font-normal text-slight-black" />
                        }
                        <AppText text="Parent" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                    </div>
                    <div className="profil-body-infos">
                        <div className="profil_info_items flex flex-wrap">
                            <AppText text="email :" className="capitalize mr-1 text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                            {email &&
                                <AppText text={email} className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black" />
                            }
                        </div>
                        <div className="profil_info flex flex-wrap">
                            <AppText text="Phone :" className="mr-1 text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                            {phoneNumber &&
                                <AppText text={phoneNumber} className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
export default ProfileCard