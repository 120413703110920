import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ApplicationRoutes from "./ApplicationRoutes"
import AuthRoutes from "./AuthRoutes"
import { IAppState } from '../_typescript/IAuthReducer';
const AppRoutes = (): JSX.Element => {
    const UserAuth = useSelector((state: { authReducer: IAppState }) => state.authReducer.IsAuthenticated)
    return (
        <Router>
            {!UserAuth ? <AuthRoutes /> : <ApplicationRoutes />}
        </Router >
    )
}
export default AppRoutes