import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { AppButton, AppImage, AppText } from '../../../../../../../components';
import RemoveIcon from "../../../../../../../assets/icons/Remove.svg";
import { useHistory } from 'react-router-dom';
type MandateModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const MandateModal = ({ isOpen, setIsOpen }: MandateModalProps) => {
    const history = useHistory()
    const { t } = useTranslation()
    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setIsOpen}>
                <div className=" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md ">
                            <div className="bg-white p-20 ">
                                <div className="flex flex-col m-auto gap-4 ">
                                    <AppImage
                                        handleClick={() => setIsOpen(false)}
                                        src={RemoveIcon}
                                        alt="x"
                                        className="h-8 w-8 cursor-pointer absolute top-7 right-7"
                                    />

                                    <Dialog.Title as="h2" className="text-2xl text-center leading-full font-NeueHaasUnica not-italic font-normal text-slight-black">
                                        {t("dont_have_any_insurances_yet")}
                                    </Dialog.Title>

                                    <AppText className='text-sm text-center' text="dont_have_any_insurances_yet_desc" />
                                    <AppButton
                                        textStyle='text-white pr-4'
                                        className='bg-blue-button rounded-4 p-4 w-full'
                                        text='Create_mondate'
                                        onClick={() => {
                                            history.push('/mandate')
                                            setIsOpen(false)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog >
        </Transition.Root >
    )
}

export default MandateModal
