export enum AuthTypes {
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
    FORGET_PSW_SUCCESS = 'FORGET_PSW_SUCCESS',
    FORGET_PSW_FAILURE = 'FORGET_PSW_FAILURE',
    CHANGE_PSW_SUCCESS = 'CHANGE_PSW_SUCCESS',
    CHANGE_PSW_FAILURE = 'CHANGE_PSW_FAILURE',
    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILURE = 'REGISTRATION_FAILURE',
    REGISTRATION_ACTIVATED = 'REGISTRATION_ACTIVATED',
    REGISTRATION_ACTIVATED_FAILURE = 'REGISTRATION_ACTIVATED_FAILURE',
    REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE',
    CONFIRMATION_REFRESH_TOKEN_SUCCESS = 'CONFIRMATION_REFRESH_TOKEN_SUCCESS',
    CONFIRMATION_REFRESH_TOKEN_FAILURE = 'CONFIRMATION_REFRESH_TOKEN_FAILURE',
    REMOVE_ERRORS = 'REMOVE_ERRORS',
}
