import INavIcon from "../../../_typescript/INavIcon"

export default function SafeLock({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 6v3"
                ></path>
                <path
                    fill={color || '#000'}
                    stroke={color || '#000'}
                    strokeWidth="0.125"
                    d="M8.313 11.25a.312.312 0 11-.625 0 .312.312 0 01.625 0z"
                ></path>
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.135 2.499l-5.499 9.5a1 1 0 00.865 1.5H13.5a1 1 0 00.865-1.5l-5.499-9.5a1 1 0 00-1.73 0v0z"
                ></path>
            </svg>
        </div>
    );
}
