type NotificationsProps = {
    className?: string;

};

const Notifications = ({ className }: NotificationsProps) => {
    return <div className={className}>
        <div>This feature is coming soon</div>
    </div>;
}
export default Notifications;