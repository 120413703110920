import { Route } from 'react-router-dom';
import AddNewInsurance from './AddNewInsurance';
import RequestNewInsurance from './RequestNewInsurance';


const AddInsuranceRoutes = (): JSX.Element => {
    return <>
        <Route exact path='/insurances/add/new_insurance' render={() => <AddNewInsurance />} />
        <Route exact path='/insurances/add/new_request' render={() => <RequestNewInsurance />} />
    </>;
}
export default AddInsuranceRoutes;