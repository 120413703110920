export enum UserTypes {
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    GET_USER_FAILURE = 'GET_USER_FAILURE',
    EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE = '   EDIT_USER_FAILURE',
    EDIT_USER_IMAGE_SUCCESS = 'EDIT_USER_IMAGE_SUCCESS',
    EDIT_USER_IMAGE_FAILURE = 'EDIT_USER_IMAGE_FAILURE',
    EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS',
    EDIT_EMAIL_FAILURE = 'EDIT_EMAIL_FAILURE',
    CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE',
    EDIT_PHONE_SUCCESS = 'EDIT_PHONE_SUCCESS',
    EDIT_PHONE_FAILURE = 'EDIT_PHONE_FAILURE',
    CONFIRM_PHONE_SUCCESS = 'CONFIRM_PHONE_SUCCESS',
    CONFIRM_PHONE_FAILURE = 'CONFIRM_PHONE_FAILURE',
    EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS',
    EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE',
    USER_INFOS_COMPLETED = 'USER_INFOS_COMPLETED',
    USER_INFOS_NOT_COMPLETED = 'USER_INFOS_NOT_COMPLETED',
    SET_PERCENTAGE = 'SET_PERCENTAGE',
}
