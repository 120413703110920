import { useState } from 'react'
import { AppText, AppImage } from '../../../components/general';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from "../../../_redux/actions/auth"
import { forgetPasswordApi } from '../../../api/authApi'
import { AuthTypes } from '../../../_redux/types/auth'
import { ForgetPswValidators } from "../../../constants/form.validators"
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import IFormValidator from '../../../_typescript/IFormValidator'
import { EmailInputField, FormFooter, FormHeader } from "../../../containers/form";

import './index.css'
import { IAppState } from '../../../_typescript/IAuthReducer';
import { AppLogoIcon } from '../../../assets/icons/navIcons';
import { appAction } from '../../../_redux/actions/app';
import { AppTypes } from '../../../_redux/types/app';

const ForgetPassword = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory();
    // translation hook
    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false)

    const errorForgetPswMessage = useSelector((state: { authReducer: IAppState }) => state?.authReducer?.errors?.forget_psw_msg);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormValidator>({
        resolver: yupResolver(ForgetPswValidators),
    });


    const handleFormSubmit = (email = "") => {
        // start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload
        setIsTyping(false)
        authAction('')(AuthTypes.REMOVE_ERRORS)(dispatch)
        const type = AuthTypes.FORGET_PSW_SUCCESS
        const payload = { email, type }
        forgetPasswordApi(email).then((res: any) => {
            if (res.status === 200) {
                authAction(payload)(AuthTypes.FORGET_PSW_SUCCESS)(dispatch)
                history.push('/authentication-code')
            } else {
                authAction(res?.data?.detail)(AuthTypes.FORGET_PSW_FAILURE)(dispatch)
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        })
            .catch((err) => {
                console.log('err', err);
            })
    }

    return (
        <div className="page_container flex items-center justify-center h-full mobile:mr-auto mobile:ml-auto mobile:mt-4 mobile:mb-4 mobile:w-10/12">
            <form
                className="form flex flex-col bg-white p-8 rounded-32xl"
                onSubmit={handleSubmit((data) => handleFormSubmit(data?.email))}
            >
                <AppImage className='w-36 h-20 self-center mb-16 mt-2' src={AppLogoIcon} alt="app-logo" />

                <FormHeader title_text={t("Forgot_your_password?")} />
                <div className="form_body ">
                    <div className="form_input-container mb-4">
                        <AppText
                            text="Enter_your_email_address"
                            className="text-sm mb-4 mt-32xl  text-slight-black leading-4 font-NeueHaasUnica not-italic font-normal "
                        />
                        <EmailInputField
                            setValue={setValue}
                            isTyping={isTyping}
                            setIsTyping={setIsTyping}
                            className='relative '
                            register={register}
                            error={errors.email}
                        />
                        {!isTyping && errorForgetPswMessage ? <AppText text={errorForgetPswMessage} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" /> : null}
                    </div>
                </div>

                <FormFooter
                    button_style='mb-32xl'
                    button_text={t("Request_reset_link")}
                    link_text={t('Back_to_sign_up')}
                    link_path="/sign-in"
                    withArrow={true}
                />


            </form>
        </div >
    )
}
export default ForgetPassword;
