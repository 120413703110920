import { AppExternalLink } from "../../components/index"
interface PrivacyPolicyFormProps {
    text: string;
    className?: string;
    first_link_url: string;
    second_link_url: string;
    first_link_text: string;
    second_link_text: string;
}

export default function PrivacyPolicyForm({ text, className, first_link_text, first_link_url, second_link_text, second_link_url }: PrivacyPolicyFormProps): JSX.Element {
    return (
        <p className={className}>
            <span className="mr-1"> {text}</span>
            <AppExternalLink
                className={className}
                to={first_link_url}
                text={first_link_text}
            />
            <span className="mr-1 ml-1">&</span>
            <AppExternalLink
                className={className}
                to={second_link_url}
                text={second_link_text}
            />
        </p>
    )
}
