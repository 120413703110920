
import { useTranslation } from "react-i18next";
import { AppText } from "../../../components";
import AddInsuranceRoutes from "./add_insurance_routes";
import InsuranceNavbar from "./InsuranceNavbar";

const Insurance = () => {
  return (
    <>
      <div>
        <AppText text="Add_new_insurance" className="text-2xl leading-full text-slight-black mobile:mt-4" />
        <InsuranceNavbar />
      </div>
      <div className="w-10/12 tablet:w-full mobile:w-full relative min-h-76 flex flex-col justify-between ">
        <AddInsuranceRoutes />
      </div>
    </>
  )
}

export default Insurance