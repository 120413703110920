import { Link, useHistory } from "react-router-dom";
import { AppText } from "../../../components";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import IUserReducerState from '../../../_typescript/IUserReducerState';
import "./index.css";

const ProfilNavbar = (): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory()
    const location = history.location.pathname;

    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer);
    const { completed } = UserInfos;
    const profilNavigationLinks = [{
        id: 1,
        text: t("Personal_Information"),
        link: "/profil/personal-infos",
    }, {
        id: 2,
        text: t("edit_password"),
        link: "/profil/edit_password",
    }, {
        id: 3,
        text: t("edit_email"),
        link: "/profil/edit-email",
    }, {
        id: 4,
        text: t("edit_phone_number"),
        link: "/profil/edit-phone",
    },
    {
        id: 5,
        text: t("Notifications"),
        link: "/profil/notifications",
    }]
    return (
        <div className="flex flex-col w-profil-nav laptop:w-full tablet:w-full mobile:w-full justify-between border-b border-gray-border">
            {
                completed
                    ?
                    <AppText text="Profil_Settings" className='mr-6 pb-8 text-2xl leading-full text-slight-black font-NeueHaasUnica not-italic font-normal' />
                    :
                    <AppText text="complete_personal_information" className='mr-6 pb-8 text-2xl leading-full text-slight-black font-NeueHaasUnica not-italic font-normal' />

            }
            <div className="flex overflow-x-scroll edit_infos_navbar tablet-min:w-10/12 small-device:w-full" >
                {completed ? profilNavigationLinks?.map((navigation) => (
                    <Link className={`${location === navigation.link ? "text-blue-link border-b border-blue-link" : "text-slight-black"}`} key={navigation.id} to={navigation.link}>
                        <AppText
                            styleText={{ width: "100%" }}
                            text={navigation.text}
                            className=" px-2 pb-4 small-device:px-1 text-center cursor-pointer text-base leading-full small-device:min-w-150 mobile:min-w-190 tablet:min-w-190"
                        />
                    </Link>
                )) : null}
            </div>
        </div>
    )
}
export default ProfilNavbar;