import { AppLogoutButton } from "../../../components";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import IEditUserInfo from "../../../_typescript/IEditUserInfo";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditProfileValidators } from "../../../constants/form.validators";
import { EditPhotoField, FormFooter, TextInputField, DateInputField } from "../../../containers/form";
import { useEffect, useState } from "react";
import IUserReducerState from "../../../_typescript/IUserReducerState";
import { editProfilApi } from "../../../api/userApi";
import { getCitiesAndZipCode } from '../../../api/generalApi'
import { UserAction } from "../../../_redux/actions/user";
import { UserTypes } from "../../../_redux/types/user";
import { appAction } from "../../../_redux/actions/app";
import { AppTypes } from "../../../_redux/types/app";
import { AdresseTypes } from "../../../_redux/types/adress";
import { addressAction } from "../../../_redux/actions/adress";
import IAdressState from "../../../_typescript/IAdressModel";
import FormAsyncSelect from "../../../components/form/FormAsyncSelect";
import Swal from 'sweetalert2'
import './index.css'

const PersonalInformations = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm<IEditUserInfo>({
        resolver: yupResolver(EditProfileValidators),
        defaultValues: {
            firstName: "",
            lastName: "",
            file: "",
            adress: "",
            BrokerId: "",
            city: "",
            zipCode: "",
            dateOfBirth: "",
        },
    });
    const [selectedValue, setSelectedValue] = useState({ value: "", label: "", zip: "", cities: [{ name: "", region_code: "" }] });
    const initialDate = getValues("dateOfBirth")?.replace('-', '/')
    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer?.user);
    const cities = useSelector((state: { adressReducer: IAdressState }) => state?.adressReducer?.cities);
    const zipCodes = useSelector((state: { adressReducer: IAdressState }) => state?.adressReducer?.zip);

    const {
        lastName,
        firstName,
        image,
        address,
        brokerId,
        city,
        postalCode,
        dateBirth,
        uploaded_image,
    } = UserInfos;

    // handle percentage method


    const [percentage, setPercentage] = useState(0)

    const required_fields = [
        watch('firstName'),
        watch('lastName'),
        watch('adress'),
        watch('BrokerId'),
        watch('city'),
        watch('zipCode')
    ]
    const handlePercentage = (data: (string | null | undefined)[]) => {
        // required fields to watch
        let percentageRate = 0;
        for (const i in data) {
            if (Number(i) < 4) {
                if (data[i]?.trim() !== null && data[i]?.trim() !== undefined && data[i]?.trim() !== "") {
                    percentageRate += 20 // add 20 to the percentage for required fields
                }
            } else {
                if (data[i]?.trim() !== null && data[i]?.trim() !== undefined && data[i]?.trim() !== "") {
                    percentageRate += 10 // only add 10 for city and zip code cause they will be selected together at once
                }
            }
        }
        setPercentage(percentageRate)

    }

    useEffect(() => {
        UserAction(percentage)(UserTypes.SET_PERCENTAGE)(dispatch)
    }, [percentage])
    useEffect(() => {
        handlePercentage(required_fields)
    }, [required_fields])
    useEffect(() => {
        setValue('firstName', firstName ? firstName : '')
        setValue('lastName', lastName ? lastName : '')
        setValue('adress', address ? address : '')
        setValue('BrokerId', brokerId ? brokerId : "")
        setValue('city', city ? city : '')
        setValue('zipCode', postalCode ? postalCode : '')
        setValue('dateOfBirth', dateBirth ? dateBirth : '')
        setSelectedValue({ value: city ? city : '', label: city ? city : '', zip: postalCode ? postalCode : '', cities: [{ name: city ? city : '', region_code: '' }] })
    }, [UserInfos])
    console.log('eventss setSelectedValue', selectedValue);
    useEffect(() => {
        getCitiesAndZipCode().then(res => {
            const citiesLength = res?.length;
            const citiesToUpdate = [];
            const zipCodesToUpdate = [];
            for (let i = 0; i < citiesLength; i++) {
                zipCodesToUpdate.push({
                    value: res[i].zip,
                    label: res[i].zip,
                });
                citiesToUpdate.push({
                    value: res[i].cities[0].name,
                    label: res[i].cities[0].name,
                    zip: res[i].zip,
                    region_code: res[i].cities[0].region_code
                })
            }
            addressAction(zipCodesToUpdate)(AdresseTypes.FETCH_ADRESSE)(dispatch)
            addressAction(citiesToUpdate)(AdresseTypes.FETCH_CITIES)(dispatch)
        });
    }, [])


    const handleFormSubmit = (UserData: IEditUserInfo) => {
        appAction(AppTypes.START_LOADING)(dispatch)
        const { firstName, lastName, adress, BrokerId, city, zipCode, dateOfBirth } = UserData;

        const uploaded_image = UserInfos?.uploaded_image || '';
        const new_img_exist = uploaded_image?.length > 0
        const payload: any = {
            "firstName": firstName,
            "lastName": lastName,
            "brokerId": BrokerId,
            "dateBirth": dateOfBirth,
            "address": adress,
            "postalCode": zipCode,
            "regionCode": "VD",
            "city": city,
            "identityCard": {
                "fileName": null
            },
            "image": {
                "fileName": uploaded_image
            }
        }

        !new_img_exist && delete payload.image;
        editProfilApi(payload).then(({ status, data }) => {
            if (status === 201) {
                setIsTyping(false)
                UserAction(data)(UserTypes.EDIT_USER_SUCCESS)(dispatch)
                data.userBroker && UserAction("")(UserTypes.USER_INFOS_COMPLETED)(dispatch)
                Swal.fire({
                    icon: 'success',
                    text: data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    text: data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                UserAction(data)(UserTypes.EDIT_USER_FAILURE)(dispatch)
            }
            appAction(AppTypes.STOP_LOADING)(dispatch)
        })
    }

    return (
        <>
            <form
                className="form flex flex-col justify-between w-full bg-white pl-0 small-device:py-0  mobile:py-0 mobile:pr-4 rounded-32xl"
            >
                <div className="w-3/4 laptop:w-full tablet:w-full mobile:w-full small-device:w-full">
                    <EditPhotoField
                        className="flex gap-8 pb-16 pt-12 mobile:flex-col tablet:gap-6 tablet:py-8 mobile:gap-6 mobile:pb-4 mobile:py-4"
                        text={t('Your_profile_photo')}
                        register={register}
                        src={uploaded_image ? uploaded_image! : image!}
                        error={errors.file}
                        setIsTyping={setIsTyping}
                        isTyping={isTyping}
                    />
                    <div className="flex gap-8 mobile:flex-col mobile:gap-2">
                        <div className="flex-1">
                            <TextInputField
                                isRequired
                                error={errors.firstName}
                                name="firstName"
                                placeholder="firstname"
                                label='firstname'
                                register={register}
                                setValue={setValue}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInputField
                                isRequired
                                error={errors.lastName}
                                name="lastName"
                                label='lastname'
                                placeholder="lastname"
                                register={register}
                                setValue={setValue}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                            />
                        </div>
                    </div>
                    <div className="flex gap-8 mobile:flex-col mobile:gap-2">
                        <div className="flex-1">
                            <TextInputField
                                isRequired
                                error={errors.adress}
                                name="adress"
                                label='adress'
                                placeholder="adress"
                                register={register}
                                setValue={setValue}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                            />
                        </div>
                        <div className="flex-1">
                            <TextInputField
                                isRequired
                                error={errors.BrokerId}
                                name="BrokerId"
                                label='brokerid'
                                placeholder="brokerid"
                                register={register}
                                setValue={setValue}
                                disabled={brokerId ? true : false}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                            />
                        </div>
                    </div>
                    <div className="w-1/2 mobile:w-full">
                        <DateInputField
                            selectedValue={initialDate}
                            error={errors.dateOfBirth}
                            label="birthDate"
                            name="dateOfBirth"
                            setValue={setValue}
                            setIsTyping={setIsTyping}
                            isTyping={isTyping}
                        />
                    </div>
                    <div className="flex gap-8 mb-4 mobile:flex-col mobile:gap-2">

                        <div className="flex-1">

                            <FormAsyncSelect
                                isRequired
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                placeHolder="Select a city"
                                name="city"
                                label="city"
                                setValue={setValue}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}
                            />

                        </div>
                        <div className="flex-1">
                            <FormAsyncSelect
                                isRequired
                                label="zip_code"
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                placeHolder="Select a zip code"
                                name="zipCode"
                                setValue={setValue}
                                setIsTyping={setIsTyping}
                                isTyping={isTyping}

                            />
                        </div>
                    </div>
                </div>
            </form>
            <div className="max-w-form-footer border-t border-gray-border w-full flex justify-between mobile:flex-col-reverse ">
                <AppLogoutButton className="mobile:mb-8" text={t('logout')} />
                <FormFooter
                    onClick={handleSubmit((data) => handleFormSubmit(data))}
                    button_style={`mb-4 ${!isTyping ? "opacity-20" : ""}`}
                    className="py-8 mobile:py-2"
                    button_text={t("save_changes")}
                    withArrow={false}
                    disabled={!isTyping ? true : false}
                />
            </div>
        </>
    )
}
export default PersonalInformations;