import { baseURL } from "../../../api";
import { SafeLockIcon } from "../../../assets/icons/navIcons";
import { OpenPdfFile } from "../../../utils";
import IUserReducerState from "../../../_typescript/IUserReducerState";
import Card from "../../card";
import SafeDocument from "../SafeCard/SafeDocument";

type SafeCardProps = {
    className?: string;
    mandate: IUserReducerState['user']['mandate'] | null;

};

const DocumentCard = ({ className, mandate }: SafeCardProps) => {
    return (
        <Card
            showDiscoverMore={false}
            title="my_documents"
            logo={<SafeLockIcon color="#1356DC" />}
            className={className + " rounded border border-gray-border max-w-card-width w-full max-h-card-height "} >
            <div className="w-full h-full pt-1.5 pb-1.5 pl-4 pr-4 mobile:p-2 ">
                <SafeDocument
                    handleClick={() => OpenPdfFile(`${baseURL}${mandate?.signedContract}`)}
                    className="cursor-pointer border-b border-gray-border pb-1.5"
                    text="Article-45-LSA.pdf"
                    type="pdf"
                    date="29 June 2021 12:15" />
                <SafeDocument
                    handleClick={() => OpenPdfFile(`${baseURL}${mandate?.signedManagement}`)}
                    className="cursor-pointer border-b border-gray-border pb-1.5"
                    text="Management-and-brokerage-mandate.pdf"
                    type="pdf"
                    date="29 June 2021 12:15" />
            </div>
        </Card>
    );
}
export default DocumentCard;