import InsurancesRoutes from "./insurance_routes";
import { useSelector } from "react-redux";
import IUserReducerState from "../../_typescript/IUserReducerState";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import './index.css'
const Insurances = (): JSX.Element => {
    const history = useHistory()
    const { mandate } = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer?.user);

    useEffect(() => {
        // check if user has a mandate or redirect him to mandate page
        !mandate?.activate && history.push('/mandate');
    }, [mandate])
    return (
        <div className='profil_page bg-white mobile:max-w-85vw mobile:overflow-hidden'>
            <InsurancesRoutes />
        </div>
    )
}
export default Insurances;
