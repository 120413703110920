import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import RemoveIcon from "../../../../assets/icons/Remove.svg";
import { AppButton, AppImage } from '../../../../components';
import DocsViewer from '../pdf';
import './index.css';

type MandateModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    pdfLink: string;
    docApproved: boolean;
    setDocApproval: (docApproved: boolean) => void;
}

const SignatureMandateModal = ({ isOpen, setIsOpen, pdfLink, docApproved, setDocApproval }: MandateModalProps) => {
    const { t } = useTranslation()
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setIsOpen}>
                <div className=" flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out "
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in "
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out "
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >

                        <div className="inline-block min-w-2xl mobile:min-w-full align-bottom h-80vh bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-80">
                            <div className="bg-white p-16 mobile:px-2">
                                <div className="flex flex-col max-w-2xl gap-4 justify-between items-center h-65vh">
                                    <AppImage
                                        handleClick={() => setIsOpen(false)}
                                        src={RemoveIcon}
                                        alt="x"
                                        className="h-8 w-8 cursor-pointer absolute top-7 right-7"
                                    />

                                    <Dialog.Title as="h2" className="text-2xl text-center leading-full font-NeueHaasUnica not-italic font-normal text-slight-black">
                                        {t("Mandate_commitment")}
                                    </Dialog.Title>
                                    <div className='p-4 border rounded-4 border-gray-border w-full h-full max-h-45vh'>
                                        <DocsViewer
                                            URL={pdfLink}
                                            className='mandate-documents overflow-y-scroll' />
                                    </div>

                                    <AppButton
                                        textStyle='text-white pr-4 '
                                        className={`bg-blue-button rounded-4 p-4 w-1/2 self-center + ${docApproved ? 'opacity-50' : 'opacity-100'}`}
                                        text={!docApproved ? 'Accept' : 'Accepted'}
                                        disabled={docApproved}
                                        onClick={() => {
                                            setIsOpen(false)
                                            !docApproved && setDocApproval(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog >
        </Transition.Root >
    )
}

export default SignatureMandateModal
