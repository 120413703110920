import INavIcon from "../../../_typescript/INavIcon"

export default function Help({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 14A6 6 0 108 2a6 6 0 000 12z"
                ></path>
                <path
                    fill={color || '#000'}
                    stroke={color || '#000'}
                    strokeWidth="0.125"
                    d="M8.313 11.25a.312.312 0 11-.625 0 .312.312 0 01.625 0z"
                ></path>
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 9.5V9a2 2 0 10-2-2"
                ></path>
            </svg>
        </div>
    );
}
