import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { AppText } from "../../components";
import { GroupCard, InsurancesCard, ProfileCard, SafeCard } from "../../containers/dashboard";
import IUserReducerState from '../../_typescript/IUserReducerState';
import DocumentCard from '../../containers/dashboard/DocumentCard';

const HomePage = (): JSX.Element => {
    const { t } = useTranslation();
    const { firstName, mandate } = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer.user);
    return (
        <div className='bg-white '>
            <div className="home_page-header flex justify-between mobile:px-0 mobile:p-2 pb-8 border-b border-gray-border">
                <div>
                    <AppText text="Dashboard" className='mr-6 pb-2 text-2xl text-slight-black' />
                    {firstName && <AppText text={`${t("Welcome")}, ${firstName}`} className='text-base mobile:text-xs text-slight-black' />}</div>
            </div>
            <div className="grid lg:grid-cols-2 mobile:gap-6 mobile:mobile:px-0 gap-10 py-8 sm:justify-center mobile:pt-4">
                <InsurancesCard mandate={mandate} />
                <ProfileCard />
                <GroupCard />
                <SafeCard />
                {mandate?.activate && <DocumentCard mandate={mandate} />}
            </div>
        </div>)
}
export default HomePage
