import { useEffect, useState } from "react";
import { GetInsuranceNumbersByUser } from "../../../../../api/dashboardApi";
import { AppText } from "../../../../../components";
import InsuranceStatus from "../../../../InsuranceStatus";

type InitialStateType = {
    imported: number
    toImport: number
    total: number
}
const InitialState: InitialStateType = {
    imported: 0,
    toImport: 0,
    total: 0
};
const InsurancesInfos = (): JSX.Element => {
    const [insuranceNumbers, setInsuranceNumbers] = useState(InitialState);
    useEffect(() => {
        GetInsuranceNumbersByUser().then((res) => setInsuranceNumbers(res?.data))
    }, [])
    return (
        <div className="h-3/4 mobile:h-auto Insurance_card-body p-6 mobile:p-2 tablet:gap-4 grid grid-cols-card">
            <div className="flex h-72 max-w-104 max-h-104 mobile:max-h-79 flex-col justify-center items-center border rounded bg-light-blue bg-opacity-50 ">
                <AppText text={insuranceNumbers.total.toString()} className="text-26 text-slight-black opacity-20" />
                <AppText text="Insurances" className="text-base mobile:text-xs text-slight-black opacity-20" />
            </div>
            <div className="laptop:pl-2 mobile:pl-2 w-full">
                <InsuranceStatus status="valide" text="Validated_Insurances" insuranceNumber={insuranceNumbers.imported} />
                <InsuranceStatus status="pending" text="Pending_Insurances" insuranceNumber={insuranceNumbers.toImport} />
            </div>
        </div>

    );
}
export default InsurancesInfos;
