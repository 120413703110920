import {
    Route,
    Redirect,
    Switch
} from 'react-router-dom'
import { Login, ForgetPassword, Registration, AuthenticationCode, AuthFooter, ResetPassword } from '../pages/auth'
const AuthRoutes = (): JSX.Element => {
    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div className='mt-24 mb-28'>
                <Switch>
                    <Route exact path={"/sign-in"} component={() => <Login />} />
                    <Route exact path={"/registration"} component={() => <Registration />} />
                    <Route exact path={"/forgot-password"} component={() => <ForgetPassword />} />
                    <Route exact path={"/authentication-code"} component={() => <AuthenticationCode />} />
                    <Route exact path={"/reset-password"} component={() => <ResetPassword />} />
                    <Redirect to={"/sign-in"} />
                </Switch>
            </div>
            <AuthFooter />
        </div>
    )
}
export default AuthRoutes