import INavIcon from "../../../_typescript/INavIcon"

export default function Profil({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 14A6 6 0 108 2a6 6 0 000 12z"
                ></path>
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM3.987 12.46a4.502 4.502 0 018.025 0"
                ></path>
            </svg>
        </div>
    );
}
