import { AppButton, AppText } from "../../../components";

type CardHeaderProps = {
    className?: string;
    title: string;
    logo: JSX.Element;
    onClick?: () => void;
    showDiscoverMore?: boolean;
};

const CardHeader = ({ className, onClick, title, logo, showDiscoverMore }: CardHeaderProps): JSX.Element => {
    return (
        <div onClick={onClick} className={className}>
            <div className="Card_title flex items-center">
                {logo}
                <AppText text={title} className="text-base mobile:text-sm ml-2 text-slight-black" />
            </div>
            {showDiscoverMore && <AppButton text='Explore_More' className="border rounded border-blue-link p-2 mobile:text-xs" />}        </div>
    );
}
export default CardHeader