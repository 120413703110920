import INavIcon from "../../../_typescript/INavIcon"

export default function UploadIcon({ color = "#111", className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                fill="none"
                viewBox="0 0 17 16"
            >
                <path
                    stroke={color}
                    strokeLinecap="square"
                    d="M8.5 1.5v10M12.5 8l-4 4-4-4"
                ></path>
                <path stroke={color} d="M2.5 12v2.5h12V12"></path>
            </svg>
        </div>

    );
}
