import { AppInternLink, AppText } from "../../../components";
import ResendCodeTimer from "../ResendCodeTimer/index";
interface FormHeaderProps {
    title_text: string;
    link_text?: string | null | undefined;
    link_subtext?: string;
    link_path?: string;
    type?: string;
}


export default function FormHeader({ type, title_text, link_path, link_subtext, link_text }: FormHeaderProps): JSX.Element {
    return (
        <div className="form_header flex items-center justify-between">
            <AppText
                text={title_text}
                className="text-2xl font-NeueHaasUnica not-italic font-normal text-slight-black leading-full "
            />
            <div className="form_header-link flex">
                <AppText
                    text={link_subtext!}
                    className="text-sm text-slight-black mr-2 leading-4 font-NeueHaasUnica not-italic font-normal mobile:text-xs" />
                {type !== "resend_code" &&
                    <AppInternLink
                        text={link_text}
                        to={link_path}
                        className="text-blue-link font-NeueHaasUnica not-italic font-normal  underline text-sm leading-4 mobile:text-xs"
                    />
                }
                {type === "resend_code" &&
                    <ResendCodeTimer
                        text={link_text}
                    />

                }
            </div>
        </div>
    )
}
