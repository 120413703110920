import { InputHTMLAttributes, ChangeEvent, CSSProperties } from "react";
import "./index.css";
import { Label } from '../../index'
import IFormValidator from "../../../_typescript/IFormValidator";
import { UseFormSetValue } from "react-hook-form";

interface FormInputFieldProps {
    type: InputHTMLAttributes<HTMLInputElement>["type"];
    className?: string;
    text?: any;
    placeholder?: string;
    pattern?: string;
    defaultValue?: string;
    autocomplete?: string;
    checked?: boolean;
    id?: string;
    error?: any;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    isTyping?: boolean;
    setIsTyping?: any;
    name?: string;
    style?: CSSProperties;
    setValue?: UseFormSetValue<IFormValidator> | any;
    disabled?: boolean;
    value?: string;
}
export default function FormInputField({ type, value, disabled, style, name, isTyping, setIsTyping, text, setValue, checked, className, id, placeholder, pattern, error, onChange, onClick, ...field }: FormInputFieldProps): JSX.Element {
    return (
        <>
            <input
                name={name}
                style={style}
                value={value}
                id={id}
                type={type}
                className={type !== 'search' ? `p-4 text-base ${error ? "mb-1 input-red-border" : "mb-4 input-gray-border"} w-full h-12 outline-none  font-normal  not-italic leading-full ${className}` : className}
                pattern={pattern}
                placeholder={placeholder}
                checked={checked}
                onChange={(event) => { setValue(name, event.target.value) }}
                onClick={onClick}
                disabled={disabled}
                {...field}
            />
            {type === "checkbox" && <Label text={text} className={`${error ? "checkbox-error" : "checkbox"}`} isRequired={false} id={id} />}
        </>
    )
}
