interface LabelProps {
    text: string;
    className?: string;
    isRequired?: boolean;
    id?: string
}

export default function Label({ text, className, isRequired, id }: LabelProps): JSX.Element {
    return (
        <>
            <label className={className} htmlFor={id}>{text}</label>{isRequired ? '*' : null}
        </>
    )
}
