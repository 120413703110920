
interface AppImageProps {
    className?: string;
    src: string | undefined;
    alt?: string;
    handleClick?: () => void;
}

export default function AppImage({ src, alt, className, handleClick, ...rest }: AppImageProps): JSX.Element {
    return (
        <img {...rest} onClick={handleClick} src={src} alt={alt} className={className} />
    )
}
