import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppImage, AppText } from "../../../../components";
import AddIcon from '../../../../assets/icons/navIcons/PlusIcon'
import UploadFileIcon from "../../../../assets/icons/navIcons/UploadIcon";
import "./index.css";

const InsuranceNavbar = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const location = history.location.pathname;

    const insuranceNavigationLinks = [{
        id: 0,
        text: t("Add_new_insurance"),
        logo: <AddIcon />,
        link: "/insurances/add/new_insurance",
    }, {
        id: 1,
        text: t("Insurance_request"),
        logo: <UploadFileIcon />,
        link: "/insurances/add/new_request",
    }]

    return (
        <div className="my-8 flex flex-col w-profil-nav laptop:w-full tablet:w-full mobile:w-full justify-between border-b border-gray-border">
            <div className="flex overflow-x-scroll edit_infos_navbar" >
                {insuranceNavigationLinks?.map((navigation) => (
                    <div className="flex items-start justify-center min-w-250">
                        <Link className="flex" key={navigation.id} to={navigation.link}>
                            <AppText
                                withIcon={true}
                                icon={
                                    navigation.id === 0
                                        ?
                                        <AddIcon color={`${location === navigation.link ? "#1356DC" : "#111"}`} />
                                        :
                                        <UploadFileIcon color={`${location === navigation.link ? "#1356DC" : "#111"}`} />}
                                text={navigation.text}
                                className={`insurance-navbar pb-4 px-4 text-center cursor-pointer text-base leading-full  mobile:min-w-190 tablet:min-w-190
                            ${location === navigation.link ? "text-blue-link border-b border-blue-link" : "text-slight-black"}`}
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default InsuranceNavbar;
