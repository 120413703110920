import { useDispatch } from 'react-redux';
import axios from 'axios';
import { authAction } from '../_redux/actions/auth';
import { AuthTypes } from '../_redux/types/auth';
import { refreshTokenApi } from './authApi';

export const baseURL = 'https://seenk-dev.ewm.dev';
const api = axios.create({
    baseURL,
    headers: {
        "from": 'web',
    },
    timeout: 1000 * 10, // Wait for 5 seconds

});

api.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem('access_token');
        const headers = { ...config.headers };
        // set current language to header for all request
        headers['Accept-Language'] = localStorage.getItem('seenk:CURRENT_LANGUAGE') || 'fr';
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const newConfig = { ...config, headers };
        return newConfig;
    },
    (error) => {
        return Promise.reject(error);
    },
);
// Response interceptor for API calls
api.interceptors.response.use(
    async (response) => {
        if (response?.data?.token) {
            await localStorage.setItem('access_token', response?.data?.token);
        }
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        console.log('error.interceptors', error.response);
        if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry && error.response.data.tokenExpired) {
            originalRequest._retry = true;
            await refreshTokenApi()?.then(async (res) => {
                const dispatch = useDispatch()
                if (res?.status === 200) {
                    authAction(res?.data)(AuthTypes.REFRESH_TOKEN_SUCCESS)(dispatch)
                } else {
                    authAction(res?.data.message)(AuthTypes.REFRESH_TOKEN_FAILURE)(dispatch)
                }
            }).catch(async (err) => {
                const dispatch = useDispatch()
                authAction(err?.message)(AuthTypes.REFRESH_TOKEN_FAILURE)(dispatch)
            });
            return api(originalRequest);
        }
        return Promise.reject(error);
    },
);

export default api;
