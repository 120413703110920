import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import { routes, uncompleted_infos_routes } from './routes'
import { Sidebar, Navbar } from "../containers/index"
import { useSelector } from 'react-redux'
import IUserReducerState from '../_typescript/IUserReducerState'

const ApplicationRoutes = (): JSX.Element => {
    const { completed } = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer)
    const selectRoutes = () => completed === true ? routes : completed === false ? uncompleted_infos_routes : [{
        path: '/',
        exact: true,
        name: 'Loading Page',
        component: () => <div></div>
    }]

    return (
        <div className={`${typeof completed !== "boolean" ? 'hidden' : ''} w-full flex`}>
            <Sidebar />
            <div className='w-full bg-white relative'>
                <Navbar />
                <div className='p-8 laptop:p-6 tablet:p-4 mobile:py-2 mobile:px-3'>
                    <Switch>
                        {selectRoutes().map((route) => {
                            return (
                                <Route
                                    key={`route_${route.path}`}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props: RouteComponentProps) => (
                                        <route.component
                                            name={route.name}
                                            {...props}
                                            {...route.props}
                                        />
                                    )}
                                />
                            );
                        })
                        }
                    </Switch>
                </div>
            </div>
        </div>
    )
}
export default ApplicationRoutes