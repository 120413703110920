import { AppText, ValidationPin } from "../../components";

type InsuranceStatusProps = {
  className?: string;
  status: string;
  text: string;
  insuranceNumber: string | number;
};

export default function InsuranceStatus({ className, status, text, insuranceNumber }: InsuranceStatusProps) {
  return (
    <div className={className + " mb-4 flex items-center gap-4 mobile:gap-1 font-NeueHaasUnica not-italic font-normal"}>
      <ValidationPin className="" status={status} />
      <AppText className="text-sm mobile:text-xs text-slight-black opacity-50" text={text} />
      <AppText className="text-sm mobile:text-xs text-black" text={insuranceNumber.toString()} />
    </div>
  )
}
