import * as Yup from 'yup'

const LoginValidators = Yup.object().shape({
    username: Yup.string()
        .when("is_email", {
            is: "initial",
            then: Yup.string().trim().required('initial'),
        })
        .when("is_email", {
            is: true,
            then: Yup.string().trim()
                .matches(/^[\w-\.]+@([\w-]+\.)+[a-z]{2,}$/, "email"),
            otherwise: Yup.string().trim()
                .matches(/^[\d]{8,}$/, "phone")
        })
    ,
    password: Yup.string()
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter")
        .min(8, "Must be at least 8 characters")
}).required();

const SignUpValidators = Yup.object().shape({
    email: Yup.string().trim()
        .required("Please enter your email")
        .matches(/^[\w-\.]+@([\w-]+\.)+[a-z]{2,}$/, "Please enter a valid email address"),
    password: Yup.string().required("Please enter your password")
        .min(8, "Must be at least 8 characters")
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter"),
    phone: Yup.string().trim()
        .required("Please enter your phone number")
        .min(8, "Must be at least 8 number")
        .matches(/^[0-9]/, "Please enter a valid phone number"),
    privacy_policy: Yup.boolean().oneOf([true]),

}).required();

const ForgetPswValidators = Yup.object().shape({
    email: Yup.string().trim()
        .required("Please enter your email")
        .matches(/^[\w-\.]+@([\w-]+\.)+[a-z]{2,}$/, "Please enter a valid email address"),
}).required();

const AuthenticationCodeValidators = Yup.object().shape({
    code: Yup.string().required("Please enter your code")
}).required();

const ResetPasswordAuthentication = Yup.object().shape({
    password: Yup.string().trim().min(8, "Must be at least 8 characters")
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter")

}).required();

const ResetPasswordValidators = Yup.object().shape({
    old_password: Yup.string().trim().min(8, "Must be at least 8 characters")
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter"),
    new_password: Yup.string().trim().min(8, "Must be at least 8 characters")
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter"),
    password_confirm: Yup.string().trim().min(8, "Must be at least 8 characters")
        .matches(/(?=.*[0-9]){1}/).required("Please add a number")
        .matches(/(?=.*[A-Z]){1}/).required("Please add a capital letter")
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),

}).required();

const ResetEmailValidators = Yup.object().shape({
    email: Yup.string().trim()
        .required("Please enter your email")
        .matches(/^[\w-\.]+@([\w-]+\.)+[a-z]{2,}$/, "Please enter a valid email address"),
}).required();

const PhoneNumberValidators = Yup.object().shape({
    phone: Yup.string().trim()
        .required("Please enter your phone number")
    // .matches(/^[0-9]{9}$/, "Please enter a valid phone number"),
}).required();

const EditProfileValidators = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    BrokerId: Yup.string().required(),
    dateOfBirth: Yup.string().required(),
    adress: Yup.string().required(),
    city: Yup.string().required(),
    zipCode: Yup.string().required(),
}).required();

const PhoneConfirmationCodeValidators = Yup.object().shape({
    code: Yup.string()
        .required("Please enter your code")
}).required();

const AddInsuranceValidators = Yup.object().shape({
    provider: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required("Please select a provider"),
    type: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).required("Please select a type"),
    expirationDate: Yup.string().required("Please select a date"),
}).required();

export {
    LoginValidators,
    SignUpValidators,
    ForgetPswValidators,
    ResetPasswordValidators,
    AuthenticationCodeValidators,
    EditProfileValidators,
    ResetEmailValidators,
    PhoneNumberValidators,
    PhoneConfirmationCodeValidators,
    ResetPasswordAuthentication,
    AddInsuranceValidators
};