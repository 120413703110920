import INavIcon from "../../../_typescript/INavIcon"

export default function Group({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="17"
                fill="none"
                viewBox="0 0 18 17"
            >
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5a2 2 0 100-4 2 2 0 000 4z"
                ></path>
                <path stroke={color || '#000'}
                    d="M9 5v7M7.5 4L4 7.5M10.5 4L14 7.5"></path>
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 11a2 2 0 100-4 2 2 0 000 4zM15 11a2 2 0 100-4 2 2 0 000 4zM9 16a2 2 0 100-4 2 2 0 000 4z"
                ></path>
            </svg>
        </div>
    );
}
