import INavIcon from "../../../_typescript/INavIcon"

export default function PlusIcon({ color = "#111", className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                fill="none"
                viewBox="0 0 17 16"
            >
                <path
                    stroke={color}
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    d="M3 8h11M8.5 2.5v11"
                ></path>
            </svg>
        </div>
    );
}

