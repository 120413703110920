import { City, ZipCities } from '../../_typescript/IAdressModel';
import { ReduxAction } from '../../_typescript/IAuthReducer';
import { AdresseTypes } from '../types/adress';

interface IAdressState {
  zip: ZipCities[];
  cities: City[];
  selectedCities: City[];
}

const initialState: IAdressState = {
  zip: [],
  cities: [],
  selectedCities: [],
};

const AdressAction = (state = initialState, { type, payload }: ReduxAction): IAdressState => {
  switch (type) {
    case AdresseTypes.FETCH_ADRESSE:
      return { ...state, zip: payload };
    case AdresseTypes.FETCH_CITIES:
      return { ...state, cities: payload };
    case AdresseTypes.SET_SELECTED_CITIES:
      return { ...state, selectedCities: payload };
    default:
      return state;
  }
};
export default AdressAction;