import { useState } from 'react'
import { FieldError, UseFormRegister } from 'react-hook-form'
import { FileInputField } from '..'
import { baseURL } from '../../../api'
import { AppImage, AppText } from '../../../components'
import IEditUserInfo from '../../../_typescript/IEditUserInfo'

type EditPhotoFieldProps = {
    className?: string;
    src: string;
    text: string;
    error: FieldError | undefined;
    register: UseFormRegister<IEditUserInfo>;
    setIsTyping?: any;
    isTyping?: boolean;
}

const EditPhotoField = ({ className, src, setIsTyping, isTyping, text, error, register }: EditPhotoFieldProps) => {
    const [avatar, setAvatar] = useState("")
    const imageUrl = baseURL + src
    return (
        <div className={className}>
            <AppText
                className="font-NeueHaasUnica not-italic font-normal text-sm text-slight-black"
                text={text}
            />
            <AppImage
                className="w-20 h-20 rounded-full"
                src={avatar ? avatar : imageUrl}
                alt="profil-avatar" />
            <div className="">
                <FileInputField
                    src={baseURL + src}
                    error={error}
                    register={register}
                    setAvatar={setAvatar}
                    setIsTyping={setIsTyping}
                    isTyping={isTyping}
                />
            </div>
        </div>
    )
}
export default EditPhotoField   