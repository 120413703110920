import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { getInsurancesList } from "../../../api/insuranceApi"
import { AppButton, AppText } from "../../../components"
import Table from "../../../containers/table"
import AddIcon from '../../../assets/icons/WhitePlus.svg'
import { ITableColumn } from "../../../_typescript/ITableModel"

type InsuranceListProps = {
    className?: string
}
interface IPagination {
    value: number;
    label: number;
}

function InsuranceList({ className }: InsuranceListProps) {
    const [insurances, setInsurances] = useState<Array<ITableColumn>>([])
    const [totalInsurances, setTotalInsurances] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [pagination, setPagination] = useState<Array<IPagination>>([{ value: 1, label: 1 }])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const history = useHistory()

    useEffect(() => {
        const paginationList = [] as Array<IPagination>
        for (let i = 0; i < totalPages; i++) {
            paginationList.push({ value: i + 1, label: i + 1 })
        }
        setPagination(paginationList);
    }, [totalPages])

    useEffect(() => {
        getInsurancesList(currentPage).then((res) => {
            setInsurances(res.data)
            setTotalInsurances(res.totalItems)
            setTotalPages(res.totalPages)
        })
    }, [currentPage])
    return (
        <>
            <div className={'flex w-full flex-col justify-between ' + className}>
                <div className="flex w-full justify-between mb-8 mobile:flex-col mobile:gap-8">
                    <div className="flex flex-col gap-2">
                        <AppText text="List of insurances" className="text-2xl" />
                        <AppText text={`${totalInsurances} documents`} className="text-sm" />
                    </div>
                    <div>
                        <AppButton
                            withIcon
                            iconSrc={AddIcon}
                            textStyle="text-sm leading-4"
                            className="h-10 bg-blue-button pr-0 text-white rounded-4 flex items-center justify-center pl-4"
                            text="Add New Insurance"
                            onClick={() => history.push('/insurances/add/new_insurance')}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between min-h-78">
                <Table tableData={insurances} className="mb-10" />
                <div className="flex justify-center items-center gap-2 py-4 border-t border-gray-border">
                    {pagination.map((item, index) => (
                        <AppText
                            styleText={{ "marginLeft": "0px" }}
                            text={index < 10 ? "0" + item.value.toString() : item.value.toString()}
                            className={`flex  justify-center items-center w-12 h-12  rounded-4 cursor-pointer ${item.value === currentPage ? "bg-gray-border" : "bg-note-gray"}`}
                            onClick={() => setCurrentPage(item.value)}
                        />
                    )
                    )}
                </div>
            </div>
        </>
    )
}

export default InsuranceList