import { useHistory } from "react-router-dom";
import { AppButton, AppText } from "../../../../../components";

const MandateInfos = (): JSX.Element => {
    const history = useHistory();
    return (
        <div className="mobile:h-auto flex flex-col justify-between p-6 mobile:p-2 tablet:gap-4  ">
            <AppText className="opacity-50 mobile:text-xs" text='no_mandate_exist' />
            <AppButton
                onClick={() => history.push('/mandate')}
                text='create_mandate'
                className="w-full text-blue-link border rounded border-blue-link p-2 pl-0 mobile:text-xs my-4 mobile:my-2" />
        </div>
    );
}
export default MandateInfos;
