import { IAppState, ReduxAction } from '../../_typescript/IAuthReducer'
import { AuthTypes } from '../types/auth';

const initialState: IAppState = {
    IsAuthenticated: localStorage.getItem('access_token') ? true : false,
    currentUser: {},
    errors: {
        registration_activated_msg: "",
        forget_psw_msg: "",
        login_msg: "",
        registration_msg: ""
    },
    UserRegistrationEmail: "",
    UserRegistrationPhoneNumber: "",
    AuthenticationCodeCheck: "",
    activation_code: ""
};
const authReducer = (state = initialState, { type, payload }: ReduxAction) => {
    switch (type) {
        case AuthTypes.LOGIN_SUCCESS:
            return { ...state, IsAuthenticated: true, currentUser: payload, errors: { login_msg: "" } };
        case AuthTypes.LOGIN_FAILURE:
            return { ...state, errors: { login_msg: payload } };
        case AuthTypes.SIGN_OUT_SUCCESS:
            return { ...state, IsAuthenticated: false, currentUser: payload };
        case AuthTypes.REGISTRATION_SUCCESS:
            return { ...state, UserRegistrationPhoneNumber: payload?.phoneNumber, UserRegistrationEmail: payload?.email, AuthenticationCodeCheck: payload?.type, errors: { registration_msg: "" } };
        case AuthTypes.REGISTRATION_FAILURE:
            return { ...state, errors: { registration_msg: payload } };
        case AuthTypes.FORGET_PSW_SUCCESS:
            return { ...state, AuthenticationCodeCheck: payload?.type, UserRegistrationEmail: payload?.email, activation_code: payload?.activation_code, errors: { forget_psw_msg: "" } };
        case AuthTypes.FORGET_PSW_FAILURE:
            return { ...state, errors: { forget_psw_msg: payload } };
        case AuthTypes.REGISTRATION_ACTIVATED:
            return { ...state, IsAuthenticated: true, errors: { registration_activated_msg: "" } };
        case AuthTypes.REGISTRATION_ACTIVATED_FAILURE:
            return { ...state, errors: { registration_activated_msg: payload } };
        case AuthTypes.CHANGE_PSW_SUCCESS:
            return { ...state, IsAuthenticated: true };
        case AuthTypes.REMOVE_ERRORS:
            return { ...state, errors: {} };
        default:
            return state;
    }
};

export default authReducer