export const footer_links = [
    { value: "About", link: "/about" },
    { value: "Pricing", link: "/pricing" },
    { value: "Features", link: "/features" },
    { value: "Contact_Us", link: "/contact-us" },
    { value: "TermsUse", link: "/terms-of-service" },
    { value: "Privacy_Policy", link: "/privacy-policy" },
    { value: "Help_Center", link: "/help-center" },
    { value: "Careers", link: "/careers" },
];
export const languages = [
    { value: "Language_fr", locale: "fr" },
    { value: "Language_en", locale: "en" }
];
