import { FieldError, SetFieldValue, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppText } from "../../../components"

interface Props {
    error: FieldError | undefined;
    className?: string;
    register: UseFormRegister<any>;
    setIsTyping?: (isTyping: boolean) => void;
    isTyping?: boolean;
    setValue: SetFieldValue<any>;
}

const EmailInputField = ({ error, setValue, className, register, isTyping, setIsTyping, ...rest }: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <input
                type="text"
                className={`p-4 text-base ${error ? "mb-1" : "mb-4"} w-full h-12 outline-none ${error ? 'input-red-border' : 'input-gray-border'} font-normal  not-italic leading-full`}
                placeholder={t("email")}
                {...register("email", {
                    onChange: (event: any) => {

                        isTyping === false && setIsTyping?.(true)
                        setValue("email", event.target.value)
                    },
                })}
                {...rest}
            />
            {error && <AppText text="msg_email" className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error mb-3" />}
        </div>
    )
}
export default EmailInputField