
import api, { baseURL } from './index';

const GetInsuranceNumbersByUser = async () => {
    return await api
        .get(`${baseURL}/api/v0/insurances-number-by-user`)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const GetCurrentUserInfos = async () => {
    return await api
        .get(`${baseURL}/api/v0/me`)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
export { GetInsuranceNumbersByUser, GetCurrentUserInfos };
