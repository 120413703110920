import { Dispatch } from "@reduxjs/toolkit";
import { AdresseTypes } from "../../types/adress";

export const addressAction =
    (data: any) =>
        (submitType: string) =>
            async (dispatch: Dispatch) => {
                try {
                    switch (submitType) {
                        case AdresseTypes.FETCH_CITIES:
                            dispatch({ type: AdresseTypes.FETCH_CITIES, payload: data });
                            break;
                        case AdresseTypes.FETCH_ADRESSE:
                            dispatch({ type: AdresseTypes.FETCH_ADRESSE, payload: data });
                            break;
                        case AdresseTypes.SET_SELECTED_CITIES:
                            dispatch({ type: AdresseTypes.SET_SELECTED_CITIES, payload: data });
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.log("error", error);
                }
                return null;
            };
