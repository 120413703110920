import INavIcon from "../../../_typescript/INavIcon"
export default function Broker({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke={color || '#000'}
                    d="M2 2.5a1 1 0 011-1h10a1 1 0 011 1v8a1 1 0 01-1 1h-1.5v3l-5-3H3a1 1 0 01-1-1v-8zM4.75 5.25h6M4.75 8h3.5"
                ></path>
            </svg>
        </div>
    );
}
