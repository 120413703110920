import Label from './../../../components/general/Label/index'

interface PrivacyInputFieldProps {
    className?: string;
    register?: any;
    error?: any;
}
const PrivacyInputField = ({ register, className, error, ...field }: PrivacyInputFieldProps): JSX.Element => {

    return (
        <>
            <input
                name='privacy_policy'
                id="privacy_policy"
                type={"checkbox"}
                className={className}
                {...register("privacy_policy")}
                {...field}
            />
            <Label text={""} className={`${error ? "checkbox-error" : "checkbox"}`} isRequired={false} id={"privacy_policy"} />
        </>
    )
}
export default PrivacyInputField 
