import AppText from '../AppText/index'
import AppImage from '../AppImage/index'
import ArrowRight from "./../../../assets/icons/arrow-right.svg"
import './index.css'
import { useSelector } from 'react-redux'

interface AppButtonProps {
    text: string;
    value?: string;
    className?: string;
    disabled?: boolean;
    type?: "submit" | "reset" | "button" | undefined;
    onClick?: (e: any) => void;
    withArrow?: boolean;
    withIcon?: boolean;
    iconSrc?: string;
    textStyle?: string;
}

export default function AppButton({ type, value, text, className, textStyle, withArrow = false, withIcon = false, iconSrc, onClick, disabled }: AppButtonProps): JSX.Element {
    const loading = useSelector((state: any) => state.appReducer.loading)
    return (
        <>
            <button
                className={className + " " + "flex items-center justify-between pr-4 "}
                type={type}
                disabled={disabled}
                onClick={onClick}
                value={value}
            >
                {loading &&
                    <div className='flex w-full'>
                        <div className="loader"></div>
                        <AppText className={`w-full pl-4 mobile:text-sm ${loading ? "opacity-0" : ""}` + " " + textStyle} text={text} />
                    </div>
                }
                {!loading && !withIcon &&
                    <AppText className={textStyle + " " + "w-full pl-4 mobile:pl-0 mobile:text-sm mobile:flex mobile:justify-center"} text={text} />
                }
                {!loading && withIcon &&
                    <div className='flex justify-center items-center w-full'>
                        <AppImage alt='btn_icon' className="mr-2 mt-1" src={iconSrc} />
                        <AppText className={"mobile:text-sm mobile:flex mobile:justify-center" + " " + textStyle} text={text} />
                    </div>
                }
                {withArrow && !loading && <AppImage className='' src={ArrowRight} alt='arrow' />}
            </button>
        </>
    )
}
