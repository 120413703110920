import { AppImage, AppText } from "../../../../components";
import WordIcon from "../../../../assets/icons/WordIcon.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
type SafeDocumentProps = {
    className?: string;
    type: string;
    text: string
    date: string;
    handleClick?: any;
};

const SafeDocument = ({ className, handleClick, type, text, date }: SafeDocumentProps) => {
    return (
        <div onClick={() => handleClick()} className={className + " flex mb-2.5 mt-2.5"}>
            {type === "pdf" && <AppImage className="pr-4" src={PdfIcon} alt="globe" />}
            {type !== "pdf" && <AppImage className="pr-4" src={WordIcon} alt="globe" />}
            <div className="flex flex-col">
                <AppText className="text-sm leading-4 text-slight-black" text={text} />
                <AppText className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-slight-black opacity-40" text={date} />
            </div>
        </div>
    );
}
export default SafeDocument;
