
type RequestInsuranceProps = {
    className?: string
}

const RequestInsurance = ({ className }: RequestInsuranceProps) => {
    return (
        <div className={className}>This feature is coming soon</div>
    )
}

export default RequestInsurance