import { ReduxAction } from '../../_typescript/IAuthReducer';
import IUserReducerState from '../../_typescript/IUserReducerState';
import { UserTypes } from '../types/user';

const initialState: IUserReducerState = {
    user: {
        firstName: "",
        lastName: "",
        image: "",
        address: "",
        city: "",
        brokerId: "",
        postalCode: "",
        dateBirth: "",
        uploaded_image: "",
        detail: "",
        email: " ",
    },
    errors: {},
    completed: null,
    completed_percentage: 0,
}
const userReducer = (state = initialState, { type, payload }: ReduxAction) => {
    switch (type) {
        case UserTypes.GET_USER_SUCCESS:
            return { ...state, user: { ...state.user, ...payload } };
        case UserTypes.GET_USER_FAILURE:
            return { ...state, errors: { get_user_msg: payload } };
        case UserTypes.EDIT_USER_SUCCESS:
            return { ...state, user: { ...state.user, ...payload } };
        case UserTypes.EDIT_USER_FAILURE:
            return { ...state, errors: { edit_user_msg: payload } };
        case UserTypes.EDIT_USER_IMAGE_SUCCESS:
            return { ...state, user: { ...state.user, uploaded_image: payload } };
        case UserTypes.EDIT_USER_IMAGE_FAILURE:
            return { ...state, errors: { edit_user_img_msg: payload } };
        // edit email 
        case UserTypes.EDIT_EMAIL_SUCCESS:
            return { ...state };
        case UserTypes.CONFIRM_EMAIL_SUCCESS:
            return { ...state, user: { ...state.user, email: payload } };
        case UserTypes.CONFIRM_EMAIL_FAILURE:
            return { ...state, errors: { confirm_email_msg: payload } };
        case UserTypes.EDIT_EMAIL_FAILURE:
            return { ...state, errors: { edit_email_msg: payload } };
        // edit password
        case UserTypes.EDIT_PASSWORD_SUCCESS:
            return { ...state };
        case UserTypes.EDIT_PASSWORD_FAILURE:
            return { ...state, errors: { edit_password_msg: payload } };
        // edit phone
        case UserTypes.EDIT_PHONE_SUCCESS:
            return { ...state };
        case UserTypes.CONFIRM_PHONE_SUCCESS:
            return { ...state, user: { ...state.user, phoneNumber: payload } };
        case UserTypes.CONFIRM_PHONE_FAILURE:
            return { ...state, errors: { confirm_phone_msg: payload } };
        case UserTypes.EDIT_PHONE_FAILURE:
            return { ...state, errors: { edit_password_msg: payload } };
        case UserTypes.USER_INFOS_COMPLETED:
            return { ...state, completed: true };
        case UserTypes.USER_INFOS_NOT_COMPLETED:
            return { ...state, completed: false };
        case UserTypes.SET_PERCENTAGE:
            return { ...state, completed_percentage: payload };
        default:
            return state;
    }
}
export default userReducer