import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from "../../../_redux/actions/auth"
import { resetPasswordApi } from '../../../api/authApi'
import { AuthTypes } from '../../../_redux/types/auth'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import IFormValidator from '../../../_typescript/IFormValidator'
import { ResetPasswordAuthentication } from "../../../constants/form.validators"


import './index.css'
import { FormFooter, FormHeader, PasswordInputField } from "../../../containers/form";
import { useHistory } from 'react-router-dom';
import { IAppState } from '../../../_typescript/IAuthReducer';
import { appAction } from '../../../_redux/actions/app';
import { AppTypes } from '../../../_redux/types/app';
import { AppImage } from '../../../components';
import { AppLogoIcon } from '../../../assets/icons/navIcons';


const ResetPassword = (): JSX.Element => {
    const history = useHistory();

    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false)

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IFormValidator>({
        resolver: yupResolver(ResetPasswordAuthentication),
        defaultValues: {
            password: '',
        }
    });
    const dispatch = useDispatch()
    const activation_code = useSelector((state: { authReducer: IAppState }) => state.authReducer.activation_code)
    const registration_email = useSelector((state: { authReducer: IAppState }) => state.authReducer.UserRegistrationEmail)
    const handleFormSubmit = (password = "") => {
        // start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // reset password
        const data = { smsCode: activation_code, email: registration_email, password }
        setIsTyping(false)
        authAction('')(AuthTypes.REMOVE_ERRORS)(dispatch)

        resetPasswordApi(data).then((res: any) => {
            if (res?.status === 200) {
                authAction(res?.data)(AuthTypes.CHANGE_PSW_SUCCESS)(dispatch)
                history.push('/')
            } else {
                authAction(res?.data.message)(AuthTypes.CHANGE_PSW_FAILURE)(dispatch)
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        }).catch((err) => {
            console.log('err', err);
        })
    }
    return (
        <div className="page_container flex items-center justify-center h-full mobile:mr-auto mobile:ml-auto mobile:mt-4 mobile:mb-4 mobile:w-10/12">
            <form
                className="form flex flex-col bg-white p-8 rounded-32xl"
                onSubmit={handleSubmit((data) => handleFormSubmit(data?.password))}
            >
                <AppImage className='w-36 h-20 self-center mb-16 mt-2' src={AppLogoIcon} alt="app-logo" />


                <FormHeader title_text={t("Reset_password")} />

                <PasswordInputField setValue={setValue} isTyping={isTyping} setIsTyping={setIsTyping} className='relative mt-32xl mb-6' register={register} error={errors.password} />

                <FormFooter
                    button_style='mb-32xl'
                    button_text={t("confirm")}
                    link_text={t("Back_to_sign_in")}
                    link_path={"/"}
                    withArrow={true}
                />
            </form>
        </div >
    )
}
export default ResetPassword;
