import { useState } from 'react'
import { AppText, AppImage } from '../../../components/general';
import { useSelector, useDispatch } from 'react-redux'
import { checkActivationCodeApi } from '../../../api/authApi';
import { AuthTypes } from '../../../_redux/types/auth'
import { useHistory } from 'react-router-dom';
import { authAction } from "../../../_redux/actions/auth";
import { useTranslation } from 'react-i18next';
import { AuthCodeInputField, FormFooter, FormHeader } from "../../../containers/form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import IFormValidator from '../../../_typescript/IFormValidator'
import { AuthenticationCodeValidators } from "../../../constants/form.validators"
import { IAppState } from '../../../_typescript/IAuthReducer';
import { AppLogoIcon } from '../../../assets/icons/navIcons';
import { appAction } from '../../../_redux/actions/app';
import { AppTypes } from '../../../_redux/types/app';

const AuthenticationCode = (): JSX.Element => {
    const dispatch = useDispatch()
    const history = useHistory();
    // Translation Hooks
    const { t } = useTranslation();
    const [isTyping, setIsTyping] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm<IFormValidator>({
        resolver: yupResolver(AuthenticationCodeValidators),
    });
    const registration_email = useSelector((state: { authReducer: IAppState }) => state.authReducer.UserRegistrationEmail)
    const registration_phone = useSelector((state: { authReducer: IAppState }) => state.authReducer.UserRegistrationPhoneNumber)
    const AuthenticationCodeType = useSelector((state: { authReducer: IAppState }) => state.authReducer.AuthenticationCodeCheck)
    const errorAuthCodeMessage = useSelector((state: { authReducer: IAppState }) => state.authReducer.errors.registration_activated_msg);
    const checkActivationCode = (code: string | undefined = "", type: string) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        let isForActivation = true;
        if (type !== AuthTypes.REGISTRATION_SUCCESS) {
            isForActivation = false
        }
        const account_data = { activation_code: code, email: registration_email, isForActivation }
        checkActivationCodeApi(account_data).then((res) => {
            setIsTyping(false)
            if (res.status === 200) {
                const payload = { response: res.data, account_data }
                if (type === AuthTypes.REGISTRATION_SUCCESS) {
                    authAction(payload)(AuthTypes.REGISTRATION_ACTIVATED)(dispatch)
                    history.push('/profil/personal-infos')

                } else {
                    authAction(account_data)(AuthTypes.FORGET_PSW_SUCCESS)(dispatch)
                    history.push('/reset-password')
                }
            }
            else {
                authAction(res?.data.detail)(AuthTypes.REGISTRATION_ACTIVATED_FAILURE)(dispatch)
            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        }).catch((err) => {
            console.log('err', err);
        })

    }
    return (
        <div className="page_container flex items-center justify-center h-full mobile:mr-auto mobile:ml-auto mobile:mt-4 mobile:mb-4 mobile:w-10/12">
            <form
                className="form flex flex-col bg-white p-8 rounded-32xl"
                onSubmit={handleSubmit((data) => checkActivationCode(data?.code, AuthenticationCodeType))}
            >
                <AppImage className='w-36 h-20 self-center mb-16 mt-2' src={AppLogoIcon} alt="app-logo" />

                <FormHeader
                    title_text={t("Authentication_code")}
                    type="resend_code"
                    link_text={t("Resend_SMS") + ' code'}
                />
                <div className="form_body mt-32xl mb-32xl">
                    {AuthenticationCodeType === AuthTypes.REGISTRATION_SUCCESS &&
                        <AppText
                            text={t('SMS_code_sent_to_number_ending_in') + ` ${registration_phone.slice(8,)}.`}
                            className="font-NeueHaasUnica text-sm mb-32xl text-slight-black leading-4 not-italic font-normal "
                        />
                    }
                    {AuthenticationCodeType === AuthTypes.FORGET_PSW_SUCCESS &&
                        <AppText
                            text={t("Code_sent_to_email_ending_in") + ` ${registration_email}.`}
                            className="text-sm mb-32xl text-slight-black leading-4 font-NeueHaasUnica not-italic font-normal "
                        />
                    }
                    <AuthCodeInputField isTyping={isTyping} setIsTyping={setIsTyping} className='relative ' register={register} error={errors.code} />
                    {!isTyping && errorAuthCodeMessage ? <AppText text={errorAuthCodeMessage} className="text-xs leading-4 font-NeueHaasUnica not-italic font-normal text-red-error  pt-3" /> : null}

                </div>
                {AuthenticationCodeType === AuthTypes.REGISTRATION_SUCCESS &&
                    <FormFooter
                        button_style='mb-32xl'
                        button_text={t("create_an_account")}
                        link_text={t('Back_to_sign_up')}
                        link_path="/registration"
                        withArrow={true}
                    />
                }
                {AuthenticationCodeType === AuthTypes.FORGET_PSW_SUCCESS &&
                    <FormFooter button_text={t("Confirm")}
                        button_style='mb-32xl'
                        link_text={t('Back_to_sign_in?')}
                        link_path="/sign-in"
                        withArrow={true}
                    />
                }

            </form>
        </div >
    )
}
export default AuthenticationCode;
