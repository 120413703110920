
interface SafeProps {
    className?: string
}

const Safe = ({ className }: SafeProps) => {
    return (
        <div className={className}>
            Safe
        </div>
    )
}

export default Safe