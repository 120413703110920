interface GroupProps {
    className?: string
}
const Group = ({ className }: GroupProps) => {
    return (
        <div className={className}>
            Group
        </div>
    )
}

export default Group