import { CSSProperties } from 'react';
import { useTranslation } from "react-i18next";

interface AppTextProps {
    text: string | null | undefined;
    className?: string;
    onClick?: () => void;
    style?: CSSProperties;
    id?: string;
    error_text?: string;
    withIcon?: boolean;
    icon?: JSX.Element;
    styleText?: { [key: string]: string };
}

export default function AppText({ style, styleText, icon, withIcon, text, error_text, id, className, onClick, ...rest }: AppTextProps): JSX.Element {
    const { t } = useTranslation()
    return (
        <p id={id} onClick={onClick} {...rest} className={className + ' font-NeueHaasUnica not-italic font-normal'} style={style} >
            {error_text && <span className="text-red-500">{t(error_text)}</span>}
            {text !== undefined && text !== null && text.length > 0 && <span>
                {withIcon && icon}
                <span style={styleText}>{t(text)}</span>
            </span>}
        </p>
    )
}
