import { InsuranceIcon } from "../../../assets/icons/navIcons";
import Card from "../../card";
import { useHistory } from "react-router-dom";
import IUserReducerState from "../../../_typescript/IUserReducerState";
import InsurancesInfos from "./components/InsuranceInfos";
import MandateInfos from "./components/MandateInfos";

type InsurancesCardProps = {
    className?: string;
    mandate: IUserReducerState['user']['mandate'] | null;
};

const InsurancesCard = ({ className, mandate }: InsurancesCardProps): JSX.Element => {
    const history = useHistory();

    return (
        <Card
            showDiscoverMore={mandate?.activate ? true : false}
            onClick={() => history.push('/insurances')}
            title="Insurances"
            logo={<InsuranceIcon color="#1356DC" />}
            className={className + " " + "rounded border border-gray-border w-full max-w-card-width max-h-card-height"}
        >
            {mandate?.activate
                ?
                <InsurancesInfos />
                :
                <MandateInfos />
            }
        </Card>
    );
}
export default InsurancesCard;
