import { Dispatch } from "@reduxjs/toolkit";
import { InsuranceTypes } from "../../types/insurance";

export const insuranceAction =
    (data: any) =>
        (submitType: string) =>
            async (dispatch: Dispatch) => {
                try {
                    switch (submitType) {
                        case InsuranceTypes.UPLOAD_FILE_SUCCESS:
                            dispatch({ type: InsuranceTypes.UPLOAD_FILE_SUCCESS, payload: data });
                            break;
                        case InsuranceTypes.UPLOAD_FILE_FAILURE:
                            dispatch({ type: InsuranceTypes.UPLOAD_FILE_FAILURE, payload: data });
                            break;
                        case InsuranceTypes.REMOVE_UPLOADED_FILE:
                            dispatch({ type: InsuranceTypes.REMOVE_UPLOADED_FILE, payload: data });
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.log("error", error);
                }
                return null;
            };
