import { useSelector } from "react-redux";
import IUserReducerState from "../../_typescript/IUserReducerState";
import AppSideBar from "./components/appSidebar";
import ProfilSidebar from "./components/profilSidebar";
export default function SideBar(): JSX.Element {

    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer);
    const { completed } = UserInfos

    return (
        completed === false ? <ProfilSidebar /> : completed === true ? <AppSideBar /> : <div></div>
    );
}
