import api, { baseURL } from ".";
import IEditUserInfo from "../_typescript/IEditUserInfo"

const editProfilApi = async (data: IEditUserInfo) => {
    return await api
        .post(`${baseURL}/api/v0/edit-info/38`, data)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const editEmailApi = async (data: IEditUserInfo) => {
    return await api
        .put(`${baseURL}/api/v0/edit-email/1`, { "newEmail": data })
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const editPhoneNumberApi = async (data: string) => {
    return api.put(`${baseURL}/api/v0/edit-phone-number/119`, {
        "newPhoneNumber": data,
    })
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const changePasswordApi = async (data: { password: string, newPassword: string }) => {
    return await api
        .put(`${baseURL}/api/v0/edit-password/1`, data)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const checkPhoneActivationCodeApi = async (data: string) => {
    return await api
        .get(`${baseURL}/api/v0/check-phone-number-code?smsCode=${data}`)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const EmailConfirmationApi = async (data: { email: string, emailCode: string }) => {
    return await api
        .get(`${baseURL}/api/v0/reset-email-code-login?emailCode=${data?.emailCode}&email=${data?.email}`)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}
const checkEmailActivationCodeApi = async (data: { email: string, code: string }) => {
    return await api
        .get(`${baseURL}/api/v0/check-email-code?email=${data.email}&emailCode=${data.code}&validateNewEmail=1`)
        .catch((error) => {
            if (error?.response?.data?.detail) {
                console.log("error", error?.response?.data?.title, error.response?.data.detail);
            }
            return error.response;
        })
}

export {
    editProfilApi,
    editEmailApi,
    editPhoneNumberApi,
    changePasswordApi,
    checkPhoneActivationCodeApi,
    checkEmailActivationCodeApi,
    EmailConfirmationApi
}