import { AppButton, AppInternLink } from '../../../components'
interface FormFooterProps {
    button_text: string;
    button_style?: string;
    link_text?: string | null | undefined;
    link_path?: string;
    className?: string;
    withArrow?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

const FormFooter = ({ button_text, onClick, disabled, button_style, link_text, link_path, className, withArrow }: FormFooterProps): JSX.Element => {
    return (
        <div className={className + " form_footer"}>
            <AppButton
                disabled={disabled}
                onClick={onClick}
                type="submit"
                withArrow={withArrow}
                text={button_text}
                className={button_style + " form_footer-btn text-base text-white h-12 text-center btn-primary w-full bg-blue-button rounded font-NeueHaasUnica not-italic font-normal"}
            />
            <div className="form_footer-link flex ">
                <AppInternLink
                    to={link_path}
                    text={link_text}
                    className="text-blue-link leading-full text-sm underline font-NeueHaasUnica not-italic font-normal"
                />
            </div>
        </div>
    )
}
export default FormFooter 