import api, { baseURL } from './index';
import { ILogin, IRegister, IActivationCode, IChangePassword } from '../_typescript/IAuthUser';

const signIn = async (data: ILogin) => {
  return await api
    .post(`${baseURL}/api/v0/login_check`, {
      username: data.username,
      password: data.password,
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log(error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    });
};

const forgetPasswordApi = async (email: string) => {
  return await api
    .get(`${baseURL}/api/v0/reset-email-passwd?email=${email}`)
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log("error", error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    });

};
const registrationApi = async (data: IRegister) => {

  return await api
    .post(`${baseURL}/api/v0/registration`, data)
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log("error", error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    })
}
const checkActivationCodeApi = async (data: IActivationCode) => {
  return await api
    .get(`${baseURL}/api/v0/reset-check-sms?email=${data?.email}&smsCode=${data?.activation_code}&activation=${data?.isForActivation}`)
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log("error", error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    })
}
const resetPasswordApi = async (data: IChangePassword) => {
  return await api
    .put(`${baseURL}/api/v0/reset-new-password`, data)
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log("error", error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    })
}
const refreshTokenApi = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  return await api
    .post(`${baseURL}/api/v0/token/refresh`, {
      refreshToken: refreshToken
    })
    .catch((error) => {
      if (error?.response?.data?.detail) {
        console.log("error", error?.response?.data?.title, error.response?.data.detail);
      }
      return error.response;
    })
}

export { signIn, forgetPasswordApi, registrationApi, checkActivationCodeApi, resetPasswordApi, refreshTokenApi };
