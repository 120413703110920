import { AppImage, AppText } from '../../../../components'
import { AppLogoIcon, AppLogoIconMobile } from '../../../../assets/icons/navIcons/index'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useSelector } from 'react-redux';
import IUserReducerState from '../../../../_typescript/IUserReducerState';
import ValidationIcon from '../../../../assets/icons/Validataion.svg';
import 'react-circular-progressbar/dist/styles.css';
import './index.css'

const ProfilSidebar = () => {
    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer);
    const { completed_percentage } = UserInfos;
    interface IBenefit {
        text: string;
        id: number;
    }
    const benefits: Array<IBenefit> = [{
        id: 0,
        text: "Lorem ipsum dolor",
    }, {
        id: 1,
        text: "Duis aute irure dolor in"
    }, {
        id: 2,
        text: "Excepteur sint"
    }]
    return (
        <nav className="navbar-container bg-light-gray flex flex-col min-h-screen border-r min-w-215   border-gray-border">
            <div className=" flex justify-start navbar-container_header py-4 px-6 border-b border-gray-border max-h-65 h-full mobile:p-2">
                <div className='app_logo mobile:hidden'>
                    <AppImage src={AppLogoIcon} alt="app-logo" />
                </div>
                <div className='app_logo_mobile flex items-center justify-center md:hidden'>
                    <AppImage src={AppLogoIconMobile} alt="app-logo" />
                </div>
            </div>
            <div className='flex flex-col items-center justify-center mt-24'>
                <CircularProgressbar
                    className="progress-bar"
                    value={Number(completed_percentage)}
                    text={`${completed_percentage}%`}
                    strokeWidth={3}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation: 0,
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Text size
                        textSize: '16px',
                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,
                        // Colors
                        pathColor: 'rgba(20, 79, 196, 1)',
                        textColor: '#144FC4',
                        trailColor: '#fff',
                        backgroundColor: '#144FC4',
                    })}
                />
                <AppText text="Complete your profile" className="text-lg mt-8 " />
                <AppText text="Benefits of complete profile:" className="text-sm my-4 " />
                <div className='flex flex-col items-start'>
                    {benefits.map(({ id, text }) => (
                        <div key={id} className='flex items-center justify-center mb-4'>
                            <AppImage src={ValidationIcon} alt="validation-icon" className="mr-2" />
                            <AppText text={text} className="text-sm" />
                        </div>
                    ))}
                </div>
            </div>
        </nav>)
}
export default ProfilSidebar