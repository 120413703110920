import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IUserReducerState from "../../_typescript/IUserReducerState";
import MandateApproved from "./screens/MandateApproved";

import MandateForm from "./screens/MandateForm";


const MandatePage = (): JSX.Element => {
    const history = useHistory()
    const { mandate } = useSelector((state: { userReducer: IUserReducerState }) => state?.userReducer?.user);

    const [isMandateApproved, setIsMandateApproved] = useState(false)
    const [ApprovalMessage, setApprovalMessage] = useState({ title: "Congratulation!", detail: "You have created a mandate. Now you can add your insurance" })

    useEffect(() => {
        // * check if user has a mandate to redirect him to dashboard page
        mandate?.activate && history.push('/');
    }, [])

    return !isMandateApproved
        ?
        <MandateForm
            setIsMandateApproved={setIsMandateApproved}
            setApprovalMessage={setApprovalMessage}
        />
        :
        <MandateApproved ApprovalMessage={ApprovalMessage} />

}

export default MandatePage