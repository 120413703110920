import { Fragment } from 'react'
import { COLORS } from '../../../constants/style'
interface AppExternalLinkProps {
    text: string;
    to: string;
    className?: string;
}

export default function AppExternalLink({ text, to, className }: AppExternalLinkProps): JSX.Element {
    return (
        <Fragment>
            <a href={to} target="_blank" style={{ color: COLORS["blue-link"] }} className={className}>
                {text}
            </a>
        </Fragment>
    )
}
