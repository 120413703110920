import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppLogoutButton, AppText } from "../../../components";
import { ResetEmailValidators } from "../../../constants/form.validators";
import { EmailInputField, FormFooter } from "../../../containers/form";
import { useEffect, useState } from "react";
import IUserReducerState from "../../../_typescript/IUserReducerState";
import IEditUserInfo from "../../../_typescript/IEditUserInfo";
import { editEmailApi } from "../../../api/userApi";
import { UserAction } from "../../../_redux/actions/user";
import { UserTypes } from "../../../_redux/types/user";
import Modal from "../../../containers/modal";
import Swal from 'sweetalert2'
import { appAction } from "../../../_redux/actions/app";
import { AppTypes } from "../../../_redux/types/app";

type EditEmailProps = {
    className?: string;
};

const EditEmail = ({ className }: EditEmailProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isTyping, setIsTyping] = useState(false)
    const [dataToSend, setDataToSend] = useState({})

    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<IEditUserInfo>({
        resolver: yupResolver(ResetEmailValidators),
        defaultValues: {
            email: '',
        }
    });
    const UserInfos = useSelector((state: { userReducer: IUserReducerState }) => state.userReducer.user);
    const { email } = UserInfos;
    const [isOpen, setIsOpen] = useState(false);
    const handleFormSubmit = (data: any) => {
        //    start loading 
        appAction(AppTypes.START_LOADING)(dispatch)
        // create payload

        const new_email = data.email;
        // call edit email api
        setDataToSend(data)
        editEmailApi(new_email).then((res) => {
            setIsTyping(false)

            if (res.status === 200) {
                UserAction(new_email)(UserTypes.EDIT_EMAIL_SUCCESS)(dispatch)
                setIsOpen(true)

            } else if (res.status === 404 && res.data.type === "OK") {
                Swal.fire({
                    icon: 'success',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,
                })

            } else {
                Swal.fire({
                    icon: 'warning',
                    text: res?.data?.detail,
                    showCancelButton: false,
                    showConfirmButton: false,

                })

            }
            // stop loading
            appAction(AppTypes.STOP_LOADING)(dispatch)
        }
        ).catch(err => {
            const UserPayload = err?.response?.data?.message
            UserAction(UserPayload)(UserTypes.EDIT_EMAIL_FAILURE)(dispatch)
        })
    }
    useEffect(() => {
        email !== undefined && setValue('email', email);
    }, [UserInfos])
    return (<>
        <form
            className={`${className} pt-8 mobile:pr-4 flex w-10/12 py-8 flex-col mobile:py-0 mobile:w-full`}
        >
            <div className="flex mobile:flex-col mobile:gap-0 mobile:py-4 gap-8">
                <div className="w-full">
                    <AppText className="pb-4" text="Your_email_address" />
                    <EmailInputField
                        setIsTyping={setIsTyping}
                        isTyping={isTyping}
                        setValue={setValue}
                        className="relative"
                        register={register}
                        error={errors.email}
                    />
                </div>
                <div className="w-full">
                </div>
            </div>
        </form >
        <div className=" max-w-form-footer border-t border-gray-border  w-full flex justify-between mobile:flex-col-reverse ">
            <AppLogoutButton className="mobile:mb-8" text={t('logout')} />
            <FormFooter
                onClick={handleSubmit((data) => handleFormSubmit(data))}
                button_style={`mb-4 ${!isTyping ? "opacity-20" : ""}`}
                className="py-8 mobile:py-2"
                button_text={t("save_changes")}
                withArrow={false}
            />
        </div>
        <Modal
            value={getValues("email")}
            type="email"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            resend={() => handleFormSubmit(dataToSend)}
        />

    </>
    )
}
export default EditEmail;