import { Route } from 'react-router-dom';
import EditEmail from "./edit_email/index";
import Notifications from "./notifications/index";
import EditPassword from "./edit_password/index";
import EditPhoneNumber from './edit_phone_number/index';
import PersonalInformations from "./personal_information";

const ProfilRoutes = (): JSX.Element => {
    return <>
        <Route exact path='/profil/personal-infos' render={() => <PersonalInformations />} />
        <Route exact path='/profil/edit_password' render={() => <EditPassword />} />
        <Route exact path='/profil/edit-email' render={() => <EditEmail />} />
        <Route exact path='/profil/edit-phone' render={() => <EditPhoneNumber />} />
        <Route exact path='/profil/notifications' render={() => <Notifications />} />
    </>;
}
export default ProfilRoutes;