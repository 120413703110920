import { useTranslation } from 'react-i18next';
interface AppListItemProps {
    className?: string;
    itemToDisplay: any;
    withLinks?: boolean;
}

export default function AppListItem({ className, itemToDisplay, withLinks }: AppListItemProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            {!withLinks && <li className={className}>{t(itemToDisplay?.value)}</li>}
            {withLinks && <li className={className}><a href={itemToDisplay?.link} target="_blank" rel="noreferrer"> {t(itemToDisplay?.value)}</a></li>}
        </>
    )

}
