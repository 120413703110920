import { Route } from 'react-router-dom';
import AddInsurance from './AddInsurance';
import InsuranceDetail from './InsuranceDetail';
import InsuranceList from './InsuranceList';


const InsuranceRoutes = (): JSX.Element => {
    return <>
        <Route path='/insurances/add/' render={() => <AddInsurance />} />
        <Route exact path='/insurances/:id' render={() => <InsuranceDetail />} />
        <Route exact path='/insurances' render={() => <InsuranceList />} />
    </>;
}
export default InsuranceRoutes;