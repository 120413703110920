import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import api, { baseURL } from "../../../../api";
import { FormFooter } from "../../../../containers/form"
import Contract from "../../components/contract"
import Signature from "../../components/signature"

type MandateFormProps = {
    setIsMandateApproved: (isMandateApproved: boolean) => void;
    setApprovalMessage: (message: { title: string, detail: string }) => void;
}


const MandateForm = ({ setIsMandateApproved, setApprovalMessage }: MandateFormProps): JSX.Element => {
    const padRef = useRef<SignatureCanvas>(null);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true)
    const [docApproved, setDocApproval] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
        setIsLoading(true)
        return padRef.current?.getTrimmedCanvas().toBlob((blob) => {
            const formData = new FormData();
            blob && formData.append('file', blob);
            api
                .post(`${baseURL}/api/v0/add-attachment-file`, formData, {
                    params: { 'attach-type': "mandate" },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    const { status, data } = res;

                    if (status === 201) {
                        api.post(`${baseURL}/api/v0/add-sign-mandate`, {
                            "signingFile": {
                                "fileName": data.fileName
                            }
                        }).then((response) => {
                            const { status, data } = response;
                            if (status === 201) {
                                setIsMandateApproved(true)
                                setIsLoading(false)
                                setApprovalMessage(data)
                            }
                            console.log("response", response);

                        })

                    }
                })
                .catch(() => {
                    throw new Error('Error in [uploadFiles]');
                });
        });
    };


    return (
        <div className="flex flex-col  laptop:w-10/12 tablet:w-96 tablet-min:w-96 mobile:w-96  justify-between items-start " >
            <div className="w-full ">
                <Contract setDocApproval={setDocApproval} docApproved={docApproved} />
                <Signature padRef={padRef} setIsEmpty={setIsSignatureEmpty} IsEmpty={isSignatureEmpty} />
            </div>
            <div className="mt-8 flex justify-end w-full border-t border-gray-modal">
                <FormFooter
                    button_text="Submit"
                    button_style="flex min-w-150 max-w-150 py-4 mobile:pr-0"
                    disabled={!docApproved && isSignatureEmpty && isLoading}
                    className={`${docApproved && !isSignatureEmpty && !isLoading ? "" : "opacity-50"} py-8 `}
                    onClick={() => handleSubmit()}
                />
            </div>
        </div>
    )
}

export default MandateForm