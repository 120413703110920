import INavIcon from "../../../_typescript/INavIcon"

export default function LoopIcon({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <g
                    stroke={color || "#111"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    opacity="0.5"
                >
                    <path d="M7.25 12.5a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5zM10.962 10.963L14 14"></path>
                </g>
            </svg>
        </div>
    );
}
