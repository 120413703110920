import { useEffect, useState } from "react";
import TooltipIcon from "../../assets/icons/Tooltip.svg";
import { ISortList, ITableColumn, ITableHeader, keysType, TableProps } from "../../_typescript/ITableModel";
import orderBy from "lodash/orderBy";
import "./index.css";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { AppText } from "../../components";

const Table = ({ className, tableData }: TableProps) => {
    const history = useHistory();
    const [columns, setColumns] = useState<Array<ITableColumn>>([]);
    const [sortListOrder, setSortListOrder] = useState<ISortList>({
        "name": { sortOrder: "asc" },
        "provider": { sortOrder: "asc" },
        "type": { sortOrder: "asc" },
        "status": { sortOrder: "asc" },
        "exp_date": { sortOrder: "asc" }
    });
    const headers = [
        { id: 0, value: "name", label: "name", width: "196px", sortBy: "name" } as ITableHeader,
        { id: 1, value: "provider", label: "provider", width: "344px", sortBy: "provider['name']" } as ITableHeader,
        { id: 2, value: "type", label: "type", width: "344px", sortBy: "type['name']" } as ITableHeader,
        { id: 3, value: "exp date", label: "exp_date", width: "196px", sortBy: "expirationDate" } as ITableHeader,
        { id: 4, value: "status", label: "status", width: "123px", sortBy: "status['label']" } as ITableHeader
    ]

    useEffect(() => {
        const ColumnToSet: Array<ITableColumn> = [];
        // set columns
        tableData?.map((column: ITableColumn) => {
            ColumnToSet.push({
                id: column.id,
                name: column.id,
                provider: column.provider,
                type: column.type,
                expirationDate: column.expirationDate,
                status: column.status
            });
        });

        setColumns(ColumnToSet);
    }, [tableData]);
    const initiateSort = (data: ISortList, label: keysType) => {
        const keysToReset = Object.keys(data).filter(item => item !== label)
        // reset the other keys to initial state 'asc'
        const initialSortList = {} as any;
        keysToReset.forEach((item) => {
            initialSortList[item] = { sortOrder: "asc" }
        })
        return initialSortList;
    }
    const sortByType = (sortBy: string, label: keysType) => {
        if (sortListOrder[label].sortOrder === "asc") {
            setSortListOrder({
                ...initiateSort(sortListOrder, label),
                [label]: { sortOrder: "desc" }
            });
        } else {
            setSortListOrder({
                ...initiateSort(sortListOrder, label),
                [label]: { sortOrder: "asc" }
            });
        }
        setColumns(orderBy(columns, sortBy, sortListOrder[label].sortOrder));
    };

    return (
        <table className={className}>
            <thead>
                <tr className="thead-item ">
                    {headers.map((header) => (
                        <th
                            onClick={() => sortByType(header.sortBy, header.label)}
                            style={{ width: header?.width }}
                            key={header?.id}
                        >
                            <div className="flex justify-start items-center">
                                <span className="font-NeueHaasUnica not-italic font-normal capitalize" style={{ marginRight: "13px" }}>{header?.value}</span>
                                <span className={sortListOrder[header.label]?.sortOrder === 'desc' ? "dropdown_icon rotate" : "dropdown_icon"}></span>
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {columns.map(
                    ({
                        id,
                        name,
                        provider,
                        type,
                        expirationDate,
                        status
                    }: ITableColumn) => (
                        <tr onClick={() => {
                            status?.id === 1
                                // TODO: redirect to brokin web when approved by the team 
                                ?
                                history.push(`/insurances/${id}`)
                                :
                                // TODO: redirect to details when it is in progress
                                console.log(`${status?.label}`)
                        }} className="tbody_item" key={id}>
                            <td className="font-NeueHaasUnica not-italic font-normal">
                                <AppText className="mobile-table-header" text={headers[0].label + " :"} />
                                #{name}</td>
                            <td className="font-NeueHaasUnica not-italic font-normal">
                                <AppText className="mobile-table-header" text={headers[1].label + " :"} />
                                {provider?.name}</td>
                            <td className="type-cell font-NeueHaasUnica not-italic font-normal flex justify-between items-center">
                                <AppText className="mobile-table-header" text={headers[2].label + " :"} />
                                <div className="flex justify-between items-center w-full">
                                    {type?.name}
                                    <img
                                        className="table-tooltip_icon"
                                        width="12px"
                                        height="12px"
                                        src={TooltipIcon}
                                        alt="tooltip_icon"
                                    />
                                </div>
                            </td>
                            <td className="font-NeueHaasUnica not-italic font-normal">
                                <AppText className="mobile-table-header" text={headers[3].label + " :"} />

                                {dayjs(expirationDate).format('DD-MM-YYYY')}</td>
                            <td className={`status-${status?.id} font-NeueHaasUnica not-italic font-normal`}>
                                <AppText className="mobile-table-header" text={headers[4].label + " :"} />
                                {status?.label}</td>
                        </tr>
                    )
                )}
            </tbody>
        </table >
    )
}

export default Table