import React from "react";
import "./index.css";
import SignaturePad from "react-signature-canvas";
import { AppImage, AppText } from "../../../../components";
import TrashIcon from "../../../../assets/icons/Trash.svg";
import SignatureIcon from "../../../../assets/icons/Signature.svg";
interface SignatureProps {
    className?: string;
    padRef: React.RefObject<SignaturePad>;
    setIsEmpty: (IsEmpty: boolean) => void;
    IsEmpty: boolean;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Signature = ({ className, padRef, setIsEmpty, IsEmpty }: SignatureProps) => {
    const clear = () => {
        padRef.current?.clear();
        setIsEmpty(true)
    };

    return (
        <div className="flex justify-between xs:gap-4 xs:flex-col">
            <AppText text="Your_signature" className="text-lg leading-6 text-slight-black  " />
            <div className={className + " relative"}>
                <SignaturePad
                    onBegin={() => setIsEmpty(false)}

                    ref={padRef} canvasProps={{ className: "sigCanvas" }} backgroundColor="#E8E8E8" />
                {!IsEmpty &&
                    <AppImage className="cursor-pointer absolute top-4 right-4 tablet-min:right-0 mobile:right-0" src={TrashIcon} handleClick={clear} />
                }
                {IsEmpty &&
                    <div className="sign-here" >
                        <AppImage className=" " src={SignatureIcon} handleClick={clear} />
                        <AppText text="Sign_here" className="text-sm leading-4 text-slight-black opacity-50 " />
                    </div>
                }
            </div>
        </div>
    );
}
export default Signature;
