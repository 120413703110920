import { IProviderModel } from '../../../_typescript/IProviderModel';
import Select from "react-select";
import { AppText } from '../../general';
import { FieldError, UseFormClearErrors } from 'react-hook-form';
import { ITypeInsuranceModel } from '../../../_typescript/ITypeInsuranceModel';
import './index.css'


type FormSimpleSelectProps = {
    className?: string;
    selectOptions: Array<IProviderModel | ITypeInsuranceModel>;
    isClearable?: boolean;
    isSearchable?: boolean;
    name: string;
    label?: string;
    labelStyle?: string;
    placeholder?: string;
    setIsTyping?: any;
    isTyping?: boolean;
    error: FieldError | any;
    setValue?: any;
    clearErrors?: UseFormClearErrors<any>
    selectedProvider?: IProviderModel;
    setSelectedProvider?: any;
    selectedType?: ITypeInsuranceModel;
    setSelectedType?: any;
}

const FormSimpleSelect = ({ className, clearErrors, selectedProvider, setSelectedProvider, selectedType, setSelectedType, isTyping, setIsTyping, placeholder, label, setValue, labelStyle, selectOptions, isClearable, isSearchable, name, error }: FormSimpleSelectProps) => {

    return (<>
        {label && <AppText text={label} className={`${labelStyle}`} />}
        <div className='relative'>
            <Select
                className={`${className} ${error ? 'has-error' : ""}`}
                placeholder={placeholder}
                defaultValue={selectOptions[0]}
                isClearable={isClearable}
                isSearchable={isSearchable}
                styles={{
                    indicatorsContainer: (provided) => ({
                        ...provided,
                        display: 'none',
                    }),
                }}
                value={name === "provider" ? selectedProvider : selectedType}
                name={name}
                options={selectOptions}
                onChange={(option: any) => {
                    isTyping === false && setIsTyping(true);
                    clearErrors!([name]);
                    setValue(name, { value: option?.name, label: option?.name, ...option })
                    if (name === "provider") {
                        setSelectedProvider({ value: option?.name, label: option?.name, ...option })
                        setSelectedType(placeholder);
                    } else {
                        setSelectedType({ value: option?.name, label: option?.name, ...option })
                    }

                }}
            />
            <span className='absolute top-6 right-4 dropdown_icon'></span>
        </div>
    </>
    )
}

export default FormSimpleSelect