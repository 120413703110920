import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const rootReducer = () => {
    return createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
};

export default rootReducer;
