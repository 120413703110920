import group_banner from '../../../assets/images/Group_banner.jpg';
import { GroupIcon } from '../../../assets/icons/navIcons';
import avatar from "../../../assets/images/Avatar.jpg";
import avatar1 from '../../../assets/images/Avatar-1.jpg';
import avatar2 from '../../../assets/images/Avatar-2.jpg';
import avatar3 from '../../../assets/images/Avatar-3.jpg';
import { AppImage, AppText } from '../../../components';
import Card from '../../card';
import { useHistory } from 'react-router-dom';

type GroupCardProps = {
    className?: string;
};
const familyGroupIcons = [
    { id: 1, src: avatar, style: "" },
    { id: 2, src: avatar1, style: "-left-3" },
    { id: 3, src: avatar2, style: "-left-6" },
    { id: 4, src: avatar3, style: "-left-9" }
];
const groupInfos = [
    { id: 1, title: "Group_members", member: '4' },
    { id: 2, title: "Invited_members", member: '1' },
]
const GroupCard = ({ className }: GroupCardProps): JSX.Element => {
    const history = useHistory();

    return <Card
        onClick={() => history.push('/group')}
        title="Your group"
        logo={<GroupIcon color="#1356DC" />}
        className={className + " " + "rounded border border-gray-border w-full max-w-card-width max-h-card-height"}
    >
        <div className="w-full h-full grid grid-cols-infos p-6 mobile:p-2">
            <AppImage alt="group-avatar" src={group_banner} />
            <div className="group-body mobile:ml-2">
                <AppText text="family-group" className="text-base mobile:text-xs text-slight-black capitalize" />
                <div className="group-body-pics flex mt-4">
                    {familyGroupIcons.map(({ id, src, style }) =>
                        <AppImage
                            src={src}
                            key={id}
                            alt="group-avatar"
                            className={"rounded-full w-8 h-8 relative " + style}
                        />
                    )}
                </div>
                <div className="flex flex-col mt-5">
                    {groupInfos.map(({ id, title, member }) =>
                        <div key={id} className="flex flex-row gap-1 tablet:-ml-24 tablet-min:ml-0 small-device:ml-0 extra-small-device:-ml-16 ">
                            <AppText text={title} className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black opacity-50" />
                            <div className='flex gap-1'>
                                <AppText className='text-base mobile:text-xs ' text={member} />
                                <AppText text="peoples" className="text-base mobile:text-xs font-NeueHaasUnica not-italic font-normal text-slight-black" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </Card>;
}
export default GroupCard;