import axios from "axios";
import { SetFieldValue } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { baseURL } from "../../../api";
import { AppText } from "../../general";
import './index.css'

type FormAsyncSelectProps = {
    placeHolder: string;
    name: string;
    setValue: SetFieldValue<any>;
    setIsTyping?: void | any;
    isTyping?: boolean;
    label?: string;
    selectedValue?: { value: string, zip: string };
    setSelectedValue?: any;
    isRequired?: boolean;
}

const FormAsyncSelect = ({ placeHolder, isRequired, selectedValue, setSelectedValue, name, setValue, setIsTyping, isTyping, label, ...rest }: FormAsyncSelectProps) => {
    const customStyles = {
        control: (styles: any) => ({
            ...styles,
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            height: "3rem"
        }),

        noOptionsMessage: (styles: any) => ({
            ...styles,
            fontSize: "0.875rem",
        }),

        placeholder: (styles: any) => ({
            ...styles,
            fontSize: "0.875rem",
            fontWeight: 500,
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
    };
    const handleChange = (currentValue: any, name: string) => {
        const { cities, zip } = currentValue;
        if (name === 'city') {
            setValue('city', cities.name);
            setSelectedValue({ value: cities.name, label: cities.name, zip: zip });
        } else {
            setValue('city', cities[0].name);
            setSelectedValue({ value: cities[0].name, label: cities[0].name, zip: zip });
        }
        setValue('zipCode', zip);
    }

    const fetchCity = async (
        _currentValue: string,
        callBack: any
    ): Promise<any> => {
        if (_currentValue.length >= 4) {
            const res = await axios.get(
                `${baseURL}/localities?city=${_currentValue}`
            );
            const cities: Array<any> = res.data;
            const cities_data = cities.map((city) => ({
                label: city.cities.name,
                value: city.cities.name,
                ...city
            }));
            callBack(cities_data);

        } else {
            callBack([{ label: '', value: '' }]);
        }
    };
    const fetchZipCode = async (
        value: string,
        callBack: any
    ): Promise<any> => {
        if (value.length >= 3) {
            const { data } = await axios.get(
                `${baseURL}/localities?zipCode=${value}`
            );

            const zipCodes: Array<any> = data;

            const zipCodes_data = zipCodes.map((city) => ({
                label: city.zip,
                value: city.zip,
                ...city
            }));
            callBack(zipCodes_data);

        } else {
            callBack([{ label: '', value: '' }]);
        }
    };

    return (
        <>
            {label && <AppText className={`${isRequired ? "required-label" : ""} pb-4`} text={label} />}
            <div className="relative">
                <AsyncSelect
                    styles={customStyles}
                    placeholder={placeHolder}
                    noOptionsMessage={() => "Aucun infos trouvé"}
                    loadingMessage={() => "Chargement..."}
                    // isClearable
                    cacheOptions
                    defaultOptions
                    value={name === 'city' ? { value: selectedValue?.value, label: selectedValue?.value } : { value: selectedValue?.zip, label: selectedValue?.zip }}
                    loadOptions={name === "city" ? fetchCity : fetchZipCode}
                    onChange={(event) => {
                        handleChange(event, name)
                        isTyping === false && setIsTyping(true)
                    }}
                    {...rest}
                />
                <span className=' absolute top-6 right-4 dropdown_icon'></span>

            </div>
        </>
    )
}
export default FormAsyncSelect