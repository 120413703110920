import { useState } from "react";
import "./index.css";
import GlobeSvg from "./icons/Globe.svg";
import { FormInputField, AppImage, AppListItem } from "../../index"
import { useTranslation } from 'react-i18next';

interface AppLanguageSwitcherProps {
    className?: string;
    languages: any;
}
export default function AppLanguageSwitcher({ languages, className }: AppLanguageSwitcherProps): JSX.Element {
    const { t, i18n } = useTranslation();
    // set initial switcher language
    const initialLanguage = i18n.language === "en"
        ?
        { locale: "en", name: "English" }
        :
        { locale: "fr", name: "Français" };
    const [activeLanguage, setActiveLanguage] = useState(initialLanguage);
    const [dropdown, setDropdown] = useState(false);
    // METHODE TO GET THE LANGUAGE FROM THE SWITCHER
    const handleSwitcher = (value: string) => {
        setDropdown(!dropdown);
        localStorage.setItem('seenk:CURRENT_LANGUAGE', value)
        i18n.changeLanguage(value);
        setActiveLanguage({ locale: value, name: t(`Language_${value}`) });
    }
    return (
        <div className={'language-switcher_container mobile:p-0 ' + className}>
            <div className="language-switcher h-12">
                <AppImage src={GlobeSvg} alt="globe" />
                <FormInputField
                    style={{ marginBottom: '0px' }}
                    type="button"
                    onClick={() => setDropdown(!dropdown)}
                    value={t(activeLanguage.name)}
                />
                <span
                    className={dropdown ? "dropdown_icon rotate" : "dropdown_icon"}
                ></span>
            </div>
            <ul className={dropdown ? "show-dropdown" : "hide-dropdown"}>
                {languages?.filter((language: any) => language.locale !== i18n.language)?.map((lang: any, index: any) => {
                    return (
                        <div key={index} onClick={() => handleSwitcher(lang.locale)}>
                            <AppListItem
                                withLinks={false}
                                key={lang.locale}
                                className={`${lang.value === activeLanguage ? "active" : ""} pl-14 mobile:pl-16`}
                                itemToDisplay={lang}
                            />
                        </div>
                    )
                })}
            </ul >
        </div >
    )
}
