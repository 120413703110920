import api from "../api";
import IUserReducerState from "../_typescript/IUserReducerState";

const HashEmail = (email: string) => {
    const name = email.substring(0, email.lastIndexOf("@"));
    const domain = email.substring(email.lastIndexOf("@"));
    const number_of_starts = name.length - 1;
    const first_letter = name[0];
    const hashed_part = []

    for (let i = 0; i < number_of_starts; i++) {
        hashed_part.push('*')
    }
    hashed_part.unshift(first_letter)
    const hashed_name = hashed_part.join('')
    return hashed_name + domain
}
const CheckProfileComplete = (profile: IUserReducerState["user"]) => {
    const { firstName, lastName, address, brokerId, postalCode, city } = profile

    if (firstName && lastName && address && brokerId && postalCode && city) {
        return { message: "complete" }
    } else {
        return { message: "incomplete" }
    }
}
const OpenPdfFile = (URL: string) => {
    api.get(URL, {
        responseType: "arraybuffer" // important
    })
        .then(response => {
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(file);
            // Open new windows and show PDF
            window.open(fileURL);
        });
}
export {
    HashEmail,
    CheckProfileComplete,
    OpenPdfFile
}