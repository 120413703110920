import { Dispatch } from "@reduxjs/toolkit";
import { AppTypes } from "../../types/app";

export const appAction =
    (submitType: string) =>
        async (dispatch: Dispatch) => {
            try {
                switch (submitType) {
                    case AppTypes.START_LOADING:
                        dispatch({ type: AppTypes.START_LOADING });
                        break;
                    case AppTypes.STOP_LOADING:
                        dispatch({ type: AppTypes.STOP_LOADING });
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.log("error", error);
            }
            return null;
        };
