import INavIcon from "../../../_typescript/INavIcon"

export default function SafeLock({ color, className }: INavIcon): JSX.Element {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    stroke={color || '#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 5.5H3a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5zM5.75 5.5V3.25a2.25 2.25 0 014.5 0V5.5"
                ></path>
            </svg>
        </div>
    );
}
