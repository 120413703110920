import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import appReducer from "./appReducer";
import adressReducer from "./adressReducer";
import insuranceReducer from "./insuranceReducer";
import { ReduxAction } from "../../_typescript/IAuthReducer";


const reducers = combineReducers({
    authReducer,
    userReducer,
    appReducer,
    adressReducer,
    insuranceReducer
})

const rootReducer = (state: any, action: ReduxAction) => {
    // RESET_STORE after every logout
    if (action.type === 'SIGN_OUT_SUCCESS') {
        return reducers(undefined, action)
    }
    return reducers(state, action)
}
export type RootState = ReturnType<typeof reducers>;
export default rootReducer;